var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("r_page_orderSubmitResult")))]),_c('hr'),_c('br'),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-alert',{staticClass:"panel-order-submit-result",attrs:{"show":""}},[_c('p',{staticClass:"title"},[_c('font-awesome-icon',{staticClass:"text-green icon-font-size",attrs:{"icon":"check"}}),_vm._v("  "+_vm._s(_vm.$t("r_orderSubmitted"))+" ")],1),_c('hr'),_c('p',{staticClass:"secondary no-margin"},[_vm._v(" "+_vm._s(_vm.$t("r_orderNumber"))+": "),_c('router-link',{attrs:{"to":{
              path: _vm.$t('r_page_orderDetails_url'),
              query: {
                orderNumber: _vm.orderNumber,
                branchNumber: _vm.branchNumber
              }
            }}},[_vm._v(" "+_vm._s(_vm.branchNumber)+"-"+_vm._s(_vm.orderNumber)+" ")])],1)])],1),(_vm.backorder === true)?_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('p',{staticClass:"text-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('r_submitOrderResultCode_50_title'))}})]),_c('hr'),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("r_submitOrderResultCode_50_text")))])])],1):_vm._e()],1),_c('br'),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }