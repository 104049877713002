import { httpHelper } from "@/helpers";

const controller = "/maintenance";

export const maintenanceService = {
  async get() {
    try {
      const response = await httpHelper.http.get(controller);

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Maintenance.Get");

      return error;
    }
  }
};
