<script>
import { NOTIFY_TYPE } from "@/helpers/constants";

// eslint-disable-next-line vue/require-name-property
export default {
  methods: {
    $_notify({ type = NOTIFY_TYPE.info, title, message }) {
      this.$bvToast.toast(message, {
        title,
        isStatus: type === NOTIFY_TYPE.info || type === NOTIFY_TYPE.success,
        toaster: "b-toaster-bottom-right",
        variant: type
      });
    }
  }
};
</script>
