export const objectHelper = { copy, isEqual, isNull, isNullObject };

function isNull(s) {
  return s === null || typeof s === "undefined";
}

function isNullObject(obj) {
  return (
    obj === null ||
    typeof obj === "undefined" ||
    !(obj instanceof Object) ||
    Object.keys(obj).length === 0
  );
}

function isEqual(objA, objB) {
  if (isNull(objA) || isNull(objB)) {
    return false;
  }

  const aProps = Object.keys(objA);
  const bProps = Object.keys(objB);

  if (aProps.length !== bProps.length) {
    return false;
  }

  return !aProps.some(propName => objA[propName] !== objB[propName]);
}

function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}
