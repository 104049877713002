<script>
import Layout from "@/views/layouts/LayoutAdmin";
import passwordChangePopup from "@/views/mixins/passwordChangePopup";

import { userService } from "@/services";

import {
  arrayHelper,
  errorHelper,
  objectHelper,
  randomHelper
} from "@/helpers";

import { STORE } from "@/store/constants";

export default {
  name: "LayoutAdminSession",

  components: { Layout },

  mixins: [passwordChangePopup],

  props: {
    showCsrCustomer: {
      type: Boolean
    }
  },

  data() {
    return {
      password: null,
      pubAccount: null,
      pubAccountUrl: ""
    };
  },

  computed: {
    authResult() {
      return this.isAuthenticated ? this.$store.state.user.authResult : {};
    },

    customer() {
      return objectHelper.isNullObject(this.authResult)
        ? {}
        : !this.user.isCSR
        ? this.authResult.customer
        : !this.showCsrCustomer && this.storeSelected
        ? this.$store.state.user.csrCustomer
        : this.authResult.customer;
    },

    isAuthenticated() {
      return this.$store.getters[STORE.user.isAuthenticated];
    },

    orderBasketCount() {
      const count = this.$store.state.orderBasket.orderBasket.length;

      return count > 0 ? `(${count})` : "";
    },

    pubAccounts() {
      return this.isAuthenticated
        ? this.$store.state.user.authResult.pubAccounts
        : null;
    },

    showMenu() {
      return !this.$store.getters[
        STORE.user.requirePasswordChangeOrAcceptTerms
      ];
    },

    storeSelected() {
      return (
        !this.user.isCSR ||
        !objectHelper.isNullObject(this.$store.state.user.csrCustomer)
      );
    },

    user() {
      return objectHelper.isNullObject(this.authResult)
        ? {}
        : this.authResult.user;
    },

    userType() {
      return this.user.isCSR
        ? this.$t("r_csr")
        : this.user.isEmployee
        ? this.$t("r_employee")
        : this.$t("r_manager");
    }
  },

  created() {
    this.updatePubAccount();
  },

  methods: {
    // Google Analytics, using VueAnalytics
    selectPage(page) {
      this.$ga.event("layoutAdminSession", "menu", "page", page);
    },

    async login() {
      // Google Analytics, using VueAnalytics
      this.$ga.event(
        "layoutAdminSession",
        "btnManagerLogin",
        "username",
        this.user.username
      );

      const command = {
        username: this.user.username,
        password: this.password
      };

      const authResult = await userService.login(command);

      if (!errorHelper.isError(authResult)) {
        if (authResult.errorCode === 0) {
          this.$store.commit(STORE.user.set, { authResult });
          this.$store.commit(STORE.part.resetCache);

          await this.$store.dispatch(
            STORE.orderBasket.get,
            authResult.customer.customerID
          );

          if (!this.$store.getters[STORE.orderBasket.isOrderBasketEmpty]) {
            const orderBasket = this.$store.state.orderBasket.orderBasket;

            const branchNumber = orderBasket[0].branchNumber;

            this.$store.commit(STORE.part.setBranchNumber, {
              branchNumber
            });
          }

          if (this.user.isManager) {
            await this.$_confirmChngPwd();

            return;
          }
        }

        await this.$bvModal.msgBoxOk(this.$t("r_login_errorCode_002_text"), {
          headerBgVariant: "warning",
          okVariant: "danger",
          title: this.$t("r_error")
        });
      }
    },

    logout() {
      // Google Analytics, using VueAnalytics
      this.$ga.event(
        "layoutAdminSession",
        "logout",
        "username",
        this.user.username
      );

      this.$store.dispatch(STORE.user.logout);

      this.$router.push(this.$t("r_page_login_url"));
    },

    updatePubAccount() {
      if (objectHelper.isNull(this.$i18n)) {
        //To prevent error while developping (hot reload)
        return;
      }

      if (!arrayHelper.isNull(this?.pubAccounts)) {
        const pubAccountsIndex = randomHelper.getRandomInt(
          0,
          this.pubAccounts.length - 1
        );

        this.pubAccount = this.pubAccounts[pubAccountsIndex];

        const path =
          this.$i18n.locale === "fr"
            ? this.pubAccount.frenchImageURL
            : this.pubAccount.englishImageURL;

        this.pubAccountUrl = `/Content/images/Pubs${path}`;

        // Every 60 seconds, change pub
        setTimeout(() => {
          this.updatePubAccount();
        }, 60 * 1000);
      }
    }
  }
};
</script>

<template>
  <layout>
    <template #slot-menu>
      <ul>
        <li>
          <a class="customer-logout" @click="logout">
            <font-awesome-icon icon="sign-out-alt" />
            {{ $t("r_page_logout") }}
          </a>
        </li>
      </ul>
      <ul v-if="showMenu && storeSelected" class="menu-compact">
        <li>{{ $t("r_page_distributionCenter") }}</li>
        <li>
          <router-link
            :to="$t('r_page_productSearch_url')"
            @click.native="selectPage('productSearch')"
          >
            {{ $t("r_page_productSearch") }}
          </router-link>
        </li>
        <li>
          <router-link
            :to="$t('r_page_nagsCatalog_url')"
            @click.native="selectPage('nagsCatalog')"
          >
            {{ $t("r_page_nagsCatalog") }}
          </router-link>
        </li>
        <li>
          <a
            :href="$t('r_page_vanfaxCatalog_url')"
            target="_blank"
            @click="selectPage('vanfaxCatalog')"
          >
            {{ $t("r_page_vanfaxCatalog") }}
          </a>
        </li>
      </ul>
      <ul v-if="showMenu && storeSelected" class="menu-compact">
        <li>{{ $t("r_page_orders") }}</li>
        <li>
          <router-link
            :to="$t('r_page_orderBasket_url')"
            @click.native="selectPage('orderBasket')"
          >
            {{ $t("r_page_orderBasket") }}
            <sup class="text-danger">{{ orderBasketCount }}</sup>
          </router-link>
        </li>
        <li>
          <router-link
            :to="$t('r_page_orderCheckout_url')"
            @click.native="selectPage('orderCheckout')"
          >
            {{ $t("r_page_orderCheckout") }}
          </router-link>
        </li>
        <li>
          <router-link
            :to="$t('r_page_currentOrders_url')"
            @click.native="selectPage('currentOrders')"
          >
            {{ $t("r_page_currentOrders") }}
          </router-link>
        </li>
        <li>
          <router-link
            :to="$t('r_page_partsOnOrder_url')"
            @click.native="selectPage('partsOnOrder')"
          >
            {{ $t("r_page_partsOnOrder") }}
          </router-link>
        </li>
      </ul>
      <ul v-if="showMenu" class="menu-compact">
        <li>{{ $t("r_page_infoCenter") }}</li>
        <li>
          <a
            :href="$t('r_page_newsletters_url')"
            target="_blank"
            @click="selectPage('newsletters')"
          >
            {{ $t("r_page_newsletters") }}
            <font-awesome-icon icon="rss" class="text-red ml-2" />
          </a>
        </li>
        <li>
          <router-link
            :to="$t('r_page_feedback_url')"
            @click.native="selectPage('feedback')"
          >
            {{ $t("r_page_feedback") }}
          </router-link>
        </li>
        <li>
          <router-link
            :to="$t('r_page_relatedLinks_url')"
            @click.native="selectPage('relatedLinks')"
          >
            {{ $t("r_page_relatedLinks") }}
          </router-link>
        </li>
        <li>
          <a
            :href="$t('r_page_careers_url')"
            target="_blank"
            @click="selectPage('careers')"
          >
            {{ $t("r_page_careers") }}
          </a>
        </li>
      </ul>
      <ul v-if="showMenu" class="menu-compact">
        <li>{{ $t("r_page_promotions") }}</li>
        <li>
          <a
            :href="$t('r_page_promotions_url')"
            target="_blank"
            @click="selectPage('promotions')"
          >
            {{ $t("r_page_promotions") }}
          </a>
        </li>
        <li v-if="user.isStoreFranchise">
          <router-link
            :to="$t('r_page_points_url')"
            @click.native="selectPage('points')"
          >
            {{ $t("r_page_points") }}
          </router-link>
        </li>
      </ul>
      <ul v-if="showMenu" class="menu-compact">
        <li>{{ $t("r_page_administration") }}</li>
        <li>
          <router-link
            :to="$t('r_page_faq_url')"
            @click.native="selectPage('faq')"
          >
            {{ $t("r_page_faq") }}
          </router-link>
        </li>
        <li v-if="user.isCSR">
          <router-link
            :to="$t('r_page_selectStore_url')"
            @click.native="selectPage('selectStore')"
          >
            {{ $t("r_page_selectStore") }}
          </router-link>
        </li>
        <li v-if="user.isManager">
          <router-link
            :to="$t('r_page_changePassword_url')"
            @click.native="selectPage('changePassword')"
          >
            {{ $t("r_page_changePassword") }}
          </router-link>
        </li>
      </ul>
    </template>

    <template #slot-main>
      <b-row>
        <b-col>
          {{ customer.warehouseName }}<br />
          {{ $t("r_customerNumber") }}: {{ customer.customerID }} –
          <span class="text-muted">{{ userType }}</span>
        </b-col>
        <b-col v-if="user.isEmployee && !user.isCSR" class="text-right">
          <b-input-group>
            <b-form-input
              id="tbxManagerPassword"
              v-model="password"
              :placeholder="$t('r_manager_password')"
              type="password"
              style="height: 53px"
              @keyup.enter="login"
            />
            <b-input-group-append>
              <button
                id="btnManagerLogin"
                class="btn-black margin-right margin-left-sm"
                :disabled="!password"
                @click="login"
              >
                {{ $t("r_manager_managerLogin") }}
              </button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <br />
      <hr />
      <p v-if="pubAccount" class="text-center no-print">
        <a
          :href="pubAccount.redirectURL"
          target="_blank"
          @click="selectPage('bannerPromo')"
        >
          <img class="banner-promo" :src="pubAccountUrl" :alt="$t('r_ad')" />
        </a>
      </p>
      <slot />
    </template>
  </layout>
</template>

<style scoped>
input[type="password"]#tbxManagerPassword {
  width: 200px;
}

#btnManagerLogin {
  width: 161px;
}
</style>
