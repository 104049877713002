import { httpHelper } from "@/helpers";

export const userService = {
  get,
  login,
  updateEmailPassword,
  updateLastTermsAndConditionsAcceptDate,
  updateLastTermsOfUseAcceptDate,
  validate
};

const controller = "/user/";

async function get(query) {
  try {
    const response = await httpHelper.get(controller, { params: query });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call User.Get");

    return error;
  }
}

async function login(command) {
  try {
    command.password = btoa(command.password);

    const response = await httpHelper.post(
      controller + "Authenticate",
      command
    );

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call User.Login");

    return error;
  }
}

async function updateEmailPassword(command) {
  try {
    command.password = btoa(command.password);

    const response = await httpHelper.put(
      controller + "UpdateEmailPassword",
      command
    );

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call User.UpdateEmailPassword");

    return error;
  }
}

async function updateLastTermsOfUseAcceptDate(command) {
  try {
    const response = await httpHelper.put(
      controller + "UpdateLastTermsOfUseAcceptDate",
      command
    );

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(
      error,
      "API Call User.UpdateLastTermsOfUseAcceptDate"
    );

    return error;
  }
}

async function updateLastTermsAndConditionsAcceptDate(command) {
  try {
    const response = await httpHelper.put(
      controller + "UpdateLastTermsAndConditionsAcceptDate",
      command
    );

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(
      error,
      "API Call User.UpdateLastTermsAndConditionsAcceptDate"
    );

    return error;
  }
}

async function validate(command) {
  try {
    command.password = btoa(command.password);

    const response = await httpHelper.post(controller + "Validate", command);

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call User.Validate");

    return error;
  }
}
