import CurrentOrders from "./views/session/CurrentOrders.vue";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import NagsCatalog from "./views/session/NagsCatalog.vue";
import OrderBasket from "./views/session/OrderBasket.vue";
import OrderCheckout from "./views/session/OrderCheckout.vue";
import OrderDetails from "./views/session/OrderDetails.vue";
import OrderSubmitResult from "./views/session/OrderSubmitResult.vue";
import PartsOnOrder from "./views/session/PartsOnOrder.vue";

const ChangePassword = () => import("./views/session/ChangePassword.vue");
const ChangePasswordEmployee = () =>
  import("./views/session/ChangePasswordEmployee.vue");
const ChangePasswordManager = () =>
  import("./views/session/ChangePasswordManager.vue");
const Faq = () => import("./views/session/Faq.vue");
const Feedback = () => import("./views/session/Feedback.vue");
const FeedbackForm = () => import("./views/session/FeedbackForm.vue");
const FeedbackCreditForm = () =>
  import("./views/session/FeedbackCreditForm.vue");
const Maintenance = () => import("./views/Maintenance.vue");
const PageNotFound = () => import("./views/PageNotFound.vue");
const PageNotFoundSession = () => import("./views/session/PageNotFound.vue");
const Points = () => import("./views/session/Points.vue");
const PointsRegister = () => import("./views/session/PointsRegister.vue");
const ProductSearch = () => import("./views/session/ProductSearch.vue");
const RelatedLinks = () => import("./views/session/RelatedLinks.vue");
const SelectStore = () => import("./views/session/SelectStore.vue");
const TermsAndConditions = () =>
  import("./views/session/TermsAndConditions.vue");
const TermsOfUse = () => import("./views/session/TermsOfUse.vue");

export default [
  {
    path: "/",
    component: Home,
    meta: { guest: true }
  },

  { path: "/en/login", component: Login, meta: { guest: true } },
  { path: "/fr/login", redirect: "/fr/connexion" },
  { path: "/fr", redirect: "/fr/connexion" },
  { path: "/en/connexion", redirect: "/en/login" },
  { path: "/en", redirect: "/en/login" },
  { path: "/fr/connexion", component: Login, meta: { guest: true } },

  {
    path: "/en/session/product-search",
    component: ProductSearch,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/product-search",
    redirect: "/fr/session/recherche-de-produits"
  },
  {
    path: "/en/session/recherche-de-produits",
    redirect: "/en/session/product-search"
  },
  {
    path: "/fr/session/recherche-de-produits",
    component: ProductSearch,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/nags-catalog",
    component: NagsCatalog,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/nags-catalog",
    redirect: "/fr/session/catalogue-nags"
  },
  {
    path: "/en/session/catalogue-nags",
    redirect: "/en/session/nags-catalog"
  },
  {
    path: "/fr/session/catalogue-nags",
    component: NagsCatalog,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/order-basket",
    component: OrderBasket,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/order-basket",
    redirect: "/fr/session/voir-le-panier"
  },
  {
    path: "/en/session/voir-le-panier",
    redirect: "/en/session/order-basket"
  },
  {
    path: "/fr/session/voir-le-panier",
    component: OrderBasket,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/order-checkout",
    component: OrderCheckout,
    meta: { storeSelected: true }
  },
  { path: "/fr/session/order-checkout", redirect: "/fr/session/commander" },
  { path: "/en/session/commander", redirect: "/en/session/order-checkout" },
  {
    path: "/fr/session/commander",
    component: OrderCheckout,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/orders",
    component: CurrentOrders,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/orders",
    redirect: "/fr/session/commandes"
  },
  {
    path: "/en/session/commandes",
    redirect: "/en/session/orders"
  },
  {
    path: "/fr/session/commandes",
    component: CurrentOrders,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/parts-on-order",
    component: PartsOnOrder,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/parts-on-order",
    redirect: "/fr/session/pieces-en-commande"
  },
  {
    path: "/en/session/pieces-en-commande",
    redirect: "/en/session/parts-on-order"
  },
  {
    path: "/fr/session/pieces-en-commande",
    component: PartsOnOrder,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/order-details",
    component: OrderDetails,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/order-details",
    redirect: "/fr/session/details-commande"
  },
  {
    path: "/en/session/details-commande",
    redirect: "/en/session/order-details"
  },
  {
    path: "/fr/session/details-commande",
    component: OrderDetails,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/order-submit-result",
    component: OrderSubmitResult,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/order-submit-result",
    redirect: "/fr/session/resultat-commande"
  },
  {
    path: "/en/session/resultat-commande",
    redirect: "/en/session/order-submit-result"
  },
  {
    path: "/fr/session/resultat-commande",
    component: OrderSubmitResult,
    meta: { storeSelected: true }
  },

  { path: "/en/session/feedback", component: Feedback },
  { path: "/fr/session/feedback", redirect: "/fr/session/commentaires" },
  { path: "/en/session/commentaires", redirect: "/en/session/feedback" },
  {
    path: "/fr/session/commentaires",
    component: Feedback
  },

  { path: "/en/session/feedback-form", component: FeedbackForm },
  {
    path: "/fr/session/feedback-form",
    redirect: "/fr/session/formulaire-commentaires"
  },
  {
    path: "/en/session/formulaire-commentaires",
    redirect: "/en/session/feedback-form"
  },
  {
    path: "/fr/session/formulaire-commentaires",
    component: FeedbackForm
  },

  { path: "/en/session/credit-form", component: FeedbackCreditForm },
  {
    path: "/fr/session/credit-form",
    redirect: "/fr/session/formulaire-credit"
  },
  {
    path: "/en/session/formulaire-credit",
    redirect: "/en/session/credit-form"
  },
  {
    path: "/fr/session/formulaire-credit",
    component: FeedbackCreditForm
  },

  {
    path: "/en/session/related-links",
    component: RelatedLinks
  },
  {
    path: "/fr/session/related-links",
    redirect: "/fr/session/liens-pertinents"
  },
  {
    path: "/en/session/liens-pertinents",
    redirect: "/en/session/related-links"
  },
  {
    path: "/fr/session/liens-pertinents",
    component: RelatedLinks
  },

  {
    path: "/en/session/points",
    component: Points,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/points",
    component: Points,
    meta: { storeSelected: true }
  },

  {
    path: "/en/session/points-register",
    component: PointsRegister,
    meta: { storeSelected: true }
  },
  {
    path: "/fr/session/points-register",
    redirect: "/fr/session/points-enregistrer"
  },
  {
    path: "/en/session/points-enregistrer",
    redirect: "/en/session/points-register"
  },
  {
    path: "/fr/session/points-enregistrer",
    component: PointsRegister,
    meta: { storeSelected: true }
  },

  { path: "/en/session/faq", component: Faq },
  { path: "/fr/session/faq", component: Faq },

  {
    path: "/en/session/select-store",
    component: SelectStore
  },
  {
    path: "/fr/session/select-store",
    redirect: "/fr/session/choisir-centre-de-service"
  },
  {
    path: "/en/session/choisir-centre-de-service",
    redirect: "/en/session/select-store"
  },
  {
    path: "/fr/session/choisir-centre-de-service",
    component: SelectStore
  },

  {
    path: "/en/session/terms-and-conditions",
    component: TermsAndConditions
  },
  {
    path: "/fr/session/terms-and-conditions",
    redirect: "/fr/session/modalites-et-conditions"
  },
  {
    path: "/en/session/modalites-et-conditions",
    redirect: "/en/session/terms-and-conditions"
  },
  {
    path: "/fr/session/modalites-et-conditions",
    component: TermsAndConditions
  },

  {
    path: "/en/session/terms-of-use",
    component: TermsOfUse
  },
  {
    path: "/fr/session/terms-of-use",
    redirect: "/fr/session/modalite-d-utilisation"
  },
  {
    path: "/en/session/modalite-d-utilisation",
    redirect: "/en/session/terms-of-use"
  },
  {
    path: "/fr/session/modalite-d-utilisation",
    component: TermsOfUse
  },

  {
    path: "/en/session/change-password",
    component: ChangePassword
  },
  {
    path: "/fr/session/change-password",
    redirect: "/fr/session/changer-mot-de-passe"
  },
  {
    path: "/en/session/changer-mot-de-passe",
    redirect: "/en/session/change-password"
  },
  {
    path: "/fr/session/changer-mot-de-passe",
    component: ChangePassword
  },

  {
    path: "/en/session/change-password-employee",
    component: ChangePasswordEmployee
  },
  {
    path: "/fr/session/change-password-employee",
    redirect: "/fr/session/changer-mot-de-passe-employe"
  },
  {
    path: "/en/session/changer-mot-de-passe-employe",
    redirect: "/en/session/change-password-employee"
  },
  {
    path: "/fr/session/changer-mot-de-passe-employe",
    component: ChangePasswordEmployee
  },

  {
    path: "/en/session/change-password-manager",
    component: ChangePasswordManager
  },
  {
    path: "/fr/session/change-password-manager",
    redirect: "/fr/session/changer-mot-de-passe-gestionnaire"
  },
  {
    path: "/en/session/changer-mot-de-passe-gestionnaire",
    redirect: "/en/session/change-password-manager"
  },
  {
    path: "/fr/session/changer-mot-de-passe-gestionnaire",
    component: ChangePasswordManager
  },

  {
    path: "/en/maintenance",
    component: Maintenance,
    meta: { guest: true }
  },
  {
    path: "/fr/maintenance",
    component: Maintenance,
    meta: { guest: true }
  },

  // Retro-compatibility
  { path: "/index.*", redirect: "/" },
  { path: "/eng/", redirect: "/en/" },
  { path: "/fra/", redirect: "/fr/" },
  { path: "/eng/*", redirect: "/en/" },
  { path: "/fra/*", redirect: "/fr/" },

  // Page not found
  {
    path: "/en/session/*",
    component: PageNotFoundSession
  },
  {
    path: "/fr/session/*",
    component: PageNotFoundSession
  },
  { path: "/*", component: PageNotFound, meta: { guest: true } }
];
