export const datetimeHelper = { addDays, today, toIsoFormat };

const isNull = date => {
  return date === null || typeof date === "undefined";
};

function addDays(days, date) {
  if (isNull(date)) {
    date = new Date();
  }

  date.setDate(date.getDate() + days);

  return date;
}

function today() {
  let date = new Date();

  date.setHours(0, 0, 0, 0);

  return date;
}

function toIsoFormat(date) {
  return isNull(date) ? null : date.replace(" ", "T") + ":00.000Z";
}
