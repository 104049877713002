import axios from "axios";
import nprogress from "nprogress";

import app from "@/main";
import { trackException } from "@/plugins/application-insights";

import { arrayHelper, objectHelper, sessionHelper } from "@/helpers";
import { NOTIFY_TYPE } from "@/helpers/constants";
import { STORE } from "@/store/constants";

const getHeader = () => {
  // return authorization header with jwt token
  let header = {
    "content-type": "application/json; charset=utf-8",
    "vanfax-app-version": process.env.APP_VERSION
  };

  const user = sessionHelper.getUser();

  if (!objectHelper.isNull(user)) {
    header.Authorization = "Bearer " + user.bearerToken;
  }

  return header;
};

const http = axios.create({
  baseURL: window.appSettings.vanfaxBkeWebApiUrl,
  withCredentials: true,
  headers: {
    "content-type": "application/json; charset=utf-8",
    Pragma: "no-cache"
  }
});

http.interceptors.request.use(
  config => {
    nprogress.start();

    delete config.headers.Authorization;
    config.headers = { ...config.headers, ...getHeader() };

    return config;
  },
  error => {
    nprogress.done();

    const err = new Error();

    err.message = "AXIOS Request Error";
    err.stack = JSON.stringify(error.toJSON());

    trackException(err);

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    nprogress.done();

    return response;
  },
  error => {
    nprogress.done();

    let logErr = true;

    if (!objectHelper.isNull(error.response)) {
      switch (error.response.status) {
        case 401:
          app.$store.dispatch(STORE.user.logout);
          app.$router.push(app.$t("r_page_login_url"));

          logErr = false;

          break;

        case 503:
          app.$store.commit(STORE.mainStore.setMaintenance);
          app.$store.dispatch(STORE.user.logout);
          app.$router.push(app.$t("r_page_maintenance_url"));

          break;
      }
    }

    if (logErr) {
      const err = new Error();

      err.message = "AXIOS Response Error";

      let stack = error.toJSON();

      stack.response = error.response;

      err.stack = JSON.stringify(stack);

      trackException(err);
    }

    return Promise.reject(error);
  }
);

const apiError = error => {
  const h = app.$createElement;

  let vNodesMsgs = [];

  let vNodesMsg = h("p", [
    "HTTP response: ",
    error.statusCode,
    " ",
    error.statusDescription
  ]);

  vNodesMsgs.push(vNodesMsg);

  if (!arrayHelper.isNull(error.errorMessages)) {
    vNodesMsg = h(
      "ul",
      error.errorMessages.map(message => h("li", [message]))
    );

    vNodesMsgs.push(vNodesMsg);
  }

  return vNodesMsgs;
};

const callApi = async fn => {
  let loader;

  try {
    // app object can be null if http called befor Vue is mounted
    if (!objectHelper.isNull(app)) {
      loader = app.$loading.show();
    }

    const result = await fn();

    if (!objectHelper.isNull(loader)) {
      loader.hide();
    }

    return result;
  } catch (error) {
    if (!objectHelper.isNull(loader)) {
      loader.hide();
    }

    return Promise.reject(error);
  }
};

const notifyApiError = (error, title) => {
  if (!app.$store.getters.inMaintenance) {
    app.$_notify({
      type: NOTIFY_TYPE.danger,
      title: title,
      message:
        objectHelper.isNull(error.response) ||
        objectHelper.isNull(error.response.data.statusCode)
          ? error.message
          : apiError(error.response.data)
    });
  }
};

export const httpHelper = {
  http,
  apiError,
  callApi,
  notifyApiError,

  get: async (url, input) => {
    return await callApi(() => http.get(url, input));
  },

  post: async (url, input) => {
    return await callApi(() => http.post(url, input));
  },

  put: async (url, input) => {
    return await callApi(() => http.put(url, input));
  },

  delete: async (url, input) => {
    return await callApi(() => http.delete(url, input));
  }
};
