<script>
import ComponentFooter from "@/views/components/ComponentFooter";

export default {
  name: "LayoutAdmin",

  components: { ComponentFooter },

  computed: {
    lang() {
      return this.$route.fullPath.indexOf("/en/") !== -1 ? "en" : "fr";
    },

    langToggled() {
      return this.lang === "fr" ? "en" : "fr";
    }
  },

  methods: {
    btnToggleLanguageClick() {
      const pathToggled = this.$route.fullPath.replace(
        "/" + this.lang + "/",
        "/" + this.langToggled + "/"
      );

      // Google Analytics, using VueAnalytics
      this.$ga.event(
        "menu",
        "btnToggleLanguageClick",
        "language",
        this.langToggled
      );

      this.$router.push(pathToggled);
    }
  }
};
</script>

<template>
  <div class="grid-layout">
    <div id="header">
      <div id="header1" class="no-print">
        <router-link to="/">
          <img src="@/assets/images/vanfax-sm.png" alt="Vanfax" />
        </router-link>
      </div>
      <div id="header2" class="no-print">
        <div>
          <img src="@/assets/images/vanfax.png" alt="Vanfax" />
          <p>{{ $t("r_header_qsv") }}</p>
        </div>
      </div>
    </div>
    <div id="content">
      <div id="menu" class="no-print">
        <slot name="slot-menu" />
        <ul>
          <li>
            <a
              class="language no-select"
              :lang="langToggled"
              @click="btnToggleLanguageClick"
            >
              {{ $t("r_page_language_code_toggled") }}
            </a>
          </li>
        </ul>
      </div>
      <div id="main">
        <div id="main-content">
          <slot name="slot-main" />
        </div>
      </div>
    </div>
    <div id="footer" class="no-print">
      <component-footer />
    </div>
  </div>
</template>
