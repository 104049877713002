<script>
import { maintenanceService } from "@/services";

import { appVersionHelper } from "@/helpers";

// eslint-disable-next-line vue/require-name-property
export default {
  async beforeCreate() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else if (await appVersionHelper.newAppVersionAvail()) {
      localStorage.setItem("reloaded", "1");
      location.reload(true);
    }

    maintenanceService.get();
  }
};
</script>
