<script>
export default {
  name: "App",

  metaInfo() {
    return {
      title: this.$t("r_welcome"),
      titleTemplate: "Vanfax | %s",
      htmlAttrs: { lang: this.lang }
    };
  },

  computed: {
    lang() {
      return this.$route.fullPath.indexOf("/en/") !== -1 ? "en" : "fr";
    }
  }
};
</script>

<template>
  <div id="app">
    <a class="sr-only" href="#main">{{ $t("r_skip_navigation") }}</a>
    <keep-alive include="NagsCatalogue">
      <router-view :key="$route.fullPath" />
    </keep-alive>
  </div>
</template>

<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media screen {
  .print-only,
  .print-only * {
    display: none !important;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Open Sans", Verdana, arial, sans-serif;
  color: #231f20;
  font-size: 15px;
  text-rendering: optimizeLegibility;
}

a,
a:hover,
a:active,
a:visited,
a:focus,
a:not([href]):not([tabindex]) {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

label {
  cursor: pointer;
}

p {
  line-height: 27px;
  margin-top: 0px;
  margin-bottom: 26px;
}

table {
  width: 100%;
  border: none;
  border-spacing: 0px;
  padding: 0px;
  border-collapse: collapse;
  vertical-align: top;
}

td {
  vertical-align: top;
  padding: 0px;
}

h2 {
  font-weight: 700;
  font-family: "Rajdhani", arial, sans-serif;
  color: #005a9c;
  display: inline-block;
  width: 100%;
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 26px;
}

.title {
  margin-bottom: 20px;
}

h3 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  font-family: "Rajdhani", arial, sans-serif;
  padding: 0px;
  line-height: 1.4;
}

.btn-red,
.btn-red:hover,
.btn-red:visited,
.btn-red:active,
.btn-red:focus a.btn-red:not([href]):not([tabindex]),
a.btn-red:not([href]):not([tabindex]):hover,
a.btn-red:not([href]):not([tabindex]):visited,
a.btn-red:not([href]):not([tabindex]):active,
a.btn-red:not([href]):not([tabindex]):focus {
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #ee020e;
  background: #ee020e;
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-red-sm,
.btn-red-sm:hover,
.btn-red-sm:visited,
.btn-red-sm:active,
.btn-red-sm:focus {
  color: #fff;
  text-transform: inherit;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #ee020e;
  background: #ee020e;
  text-align: center;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-red-outline,
.btn-red-outline:hover,
.btn-red-outline:visited,
.btn-red-outline:active,
.btn-red-outline:focus {
  color: #ee020e;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #ee020e;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-white,
.btn-white:hover,
.btn-white:visited,
.btn-white:active,
.btn-white:focus {
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #fff;
  background: #fff;
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-white-outline,
.btn-white-outline:hover,
.btn-white-outline:visited,
.btn-white-outline:active,
.btn-white-outline:focus {
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #fff;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-grey,
.btn-grey:hover,
.btn-grey:visited,
.btn-grey:active,
.btn-grey:focus,
.btn-black:disabled,
.btn-black:disabled:hover,
.btn-red:disabled,
.btn-red:disabled:hover,
.btn-blue:disabled,
.btn-blue:disabled:hover,
.btn-blue-outline:disabled,
.btn-blue-outline:disabled:hover {
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #6c7981;
  background: #6c7981;
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.icon-font-size {
  font-size: 20px !important;
}

.text-red,
.text-red:hover,
.text-red:visited,
.text-red:active,
.text-red:focus {
  color: #ee020e;
}

.bold {
  font-weight: bold;
}

.text-black {
  color: #1e1f21;
}

.border-black {
  border-color: #1e1f21;
}

.text-green {
  color: green;
}

.btn-grey-outline,
.btn-grey-outline:hover,
.btn-grey-outline:visited,
.btn-grey-outline:active,
.btn-grey-outline:focus {
  color: #6c7981;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #6c7981;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-page,
.btn-page:visited,
.btn-page:focus {
  color: #6c7981;
  text-transform: uppercase;
  font-size: 14px;
  border: 1px solid #6c7981;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  min-width: 20px;
  padding: 5px !important;
  margin-left: 2.5px;
  margin-right: 2.5px;
  font-family: "Open Sans", Verdana, arial, sans-serif;
}

.btn-page.active,
.btn-page:active,
.btn-page:hover {
  color: #005a9c;
  text-transform: uppercase;
  font-size: 14px;
  border: 1px solid #005a9c;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
  min-width: 20px;
  padding: 5px !important;
  margin-left: 2.5px;
  margin-right: 2.5px;
  font-family: "Open Sans", Verdana, arial, sans-serif;
}

.text-blue {
  color: #005a9c;
}

.btn-blue-outline,
.btn-blue-outline:visited,
.btn-blue-outline:active,
.btn-blue-outline:focus {
  color: #005a9c;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #005a9c;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-blue,
.btn-blue:hover,
.btn-blue:visited,
.btn-blue:active,
.btn-blue:focus,
.btn-blue-outline:hover,
.btn-blue-outline.active {
  color: white;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #005a9c;
  background: #005a9c;
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-blue-sm,
.btn-blue-sm:hover,
.btn-blue-sm:visited,
.btn-blue-sm:active,
.btn-blue-sm:focus,
.btn-blue-sm-outline:hover,
.btn-blue-sm-outline.active {
  color: white;
  text-transform: inherit;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #005a9c;
  background: #005a9c;
  text-align: center;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.btn-black,
.btn-black:hover,
.btn-black:visited,
.btn-black:active,
.btn-black:focus {
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #1e1f21;
  background: #1e1f21;
  text-align: center;
  text-decoration: none;
  padding: 15.5px 15.5px;
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.outline,
.outline:hover,
.outline:visited,
.outline:active,
.outline:focus {
  color: #231f20;
  font-size: 19px;
  font-family: "Rajdhani", arial, sans-serif;
  border: 2px solid #d8d8d8;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  text-decoration: none;

  padding: 16px 16px;
  border-radius: 3px;
  font-weight: 600;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  border-style: solid;
}

.outline.active,
.outline.activehover,
.outline.active:visited,
.outline.active:active,
.outline.active:focus {
  border-color: #005a9c;
}

.padding-right {
  padding-right: 30px;
}

.no-padding {
  padding: 0px;
}

.margin-right {
  margin-right: 30px;
}

.margin-right-sm {
  margin-right: 20px;
}

.margin-right-xs {
  margin-right: 10px;
}

.margin-right-xxs {
  margin-right: 5px;
}

.margin-left {
  margin-left: 30px;
}

.margin-left-sm {
  margin-left: 20px;
}

.margin-left-xs {
  margin-left: 10px;
}

.margin-left-xxs {
  margin-left: 5px;
}

.no-margin {
  margin: 0px;
}

.no-select {
  user-select: none;
}

.grid-layout {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0px;
}

.grid-layout #header {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
}

.grid-layout #content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
}

.grid-layout #main {
  flex: 1;
}

.grid-layout #menu {
  flex: 0 0 214px;
}

#footer {
  flex: none;
}

.table-vanfax,
.table.table-vanfax {
  font-size: 12px;
}

.table-vanfax th,
.table.table-vanfax th {
  background-color: #f5f5f5;
}

.table-vanfax tr:nth-child(even) td {
  background-color: #fcfcfc;
}

#header1 {
  flex: 0 0 214px;
  background-color: #1e1f21;
  color: white;
  height: 200px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}

#header1 img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#header2 {
  flex: 1;
  background-image: url("~@/assets/images/bkg-top.jpg");
  background-repeat: no-repeat;
  color: white;
  height: 200px;
  box-sizing: border-box;
}

#header2 > div {
  padding: 40px;
  margin-top: 23px;
  margin-left: 40px;
  border-left: 2px solid white;
  height: 177px;
  width: auto;
  box-sizing: border-box;
}

#header2 > div > img {
  width: 200px;
  height: 46px;
}

#header2 > div > p {
  font-size: 30px;
  font-weight: 600;
  font-family: "Rajdhani", arial, sans-serif;
  color: #fff;
  line-height: 0.9;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 24px;
}

#menu {
  padding: 48px 20px;
  box-sizing: border-box;
  border-right: 1px solid #d9d9d9;
}

#menu > ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  margin-top: 15px;
}

#menu > ul:nth-child(1) {
  margin-top: 2px;
}

#menu > ul > li {
  font-family: "Rajdhani", "Trebuchet MS", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 29px;
  text-transform: uppercase;
  color: #aaa;
}

#menu > ul.menu-compact > li {
  line-height: 23px;
}

#menu > ul > li.delimiter {
  line-height: 18px;
}

#menu > ul > li > a,
#menu > ul > li > a:visited,
#menu > ul > li > a:active,
#menu > ul > li > a:focus {
  color: rgb(35, 31, 32);
  text-decoration: none;
}

#menu > ul > li > a:hover {
  color: #ee020e;
}

#menu > ul > li > a.customer-login,
#menu > ul > li > a.customer-logout {
  color: #ee020e;
  display: inline-block;
  line-height: 21px;
  vertical-align: top;
}

#menu > ul > li > a.open-account {
  color: #005a9c;
  display: inline-block;
  line-height: 21px;
  vertical-align: top;
}

#menu > ul > li > a.language {
  color: #ee020e;
}

.banner-promo {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.lockIcon,
.accountIcon,
.downloadIcon {
  display: inline-block;
  background: url("~@/assets/images/icons.png");
  background-repeat: no-repeat;
  line-height: 19px;
  vertical-align: top;
}

.lockIcon {
  width: 15px;
  padding-top: 3px;
  height: 18px;
  margin-right: 6px;
  background-position: -43px 0;
}

.accountIcon {
  width: 15px;
  height: 15px;
  background-position: 0 -37px;
  margin-right: 6px;
  margin-top: 3px;
  vertical-align: top;
}

.downloadIcon {
  width: 18px;
  height: 17px;
  background-position: 0 -70px;
}

#main {
  padding: 45px 40px;
  box-sizing: border-box;
}

#main-content {
  max-width: 1120px;
}

#footer {
  background-image: url("~@/assets/images/bkg-bot.jpg");
  color: white;
  height: 220px;
}

#footer-content {
  width: 950px;
  margin: 60px auto 0px auto;
}

#footer-title,
#footer-contact {
  font: 700 22px rajdhani, sans-serif;
  height: 120px;
  line-height: 90px;
  text-rendering: optimizeLegibility;
  font-stretch: 100%;
}

#footer-title .v-line {
  font-size: 50px;
}

#footer-title img {
  vertical-align: middle;
}

#footer-title span {
  vertical-align: middle;
  border-left: 2px solid white;
  margin-left: 30px;
  padding-left: 30px;
  line-height: 30px;
}

#footer-contact {
  text-align: right;
}

#footer-belron,
#footer-belron a,
#footer-belron a:visited,
#footer-belron a:active,
#footer-terms,
#footer-terms a,
#footer-terms a:visited,
#footer-terms a:active {
  color: rgb(170, 170, 171);
  text-decoration: none;
  font: 400 14px openSans, sans-serif;
}

#footer-belron a:hover,
#footer-terms a:hover {
  color: white;
}

#footer-terms {
  text-align: right;
  padding-right: 60px;
}

ul.ul-vanfax {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: left;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ul.ul-vanfax li {
  list-style: none;
  font: 400 16px openSans, sans-serif;
  line-height: 30px;
  display: list-item;
  color: #231f20;
}

ul.ul-vanfax li a,
ul.ul-vanfax li span {
  margin: 0 0 0.8em 1em;
}

ul.ul-vanfax li:before {
  content: ">";
  color: #b2b4b7;
  font-family: rajdhani, sans-serif;
  font-size: 20px;
  font-weight: 700;
}

button:disabled,
select:disabled {
  cursor: no-drop;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.vf-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}
</style>
