<script>
import Layout from "@/views/layouts/LayoutAdminSession";
import ProductSearchForm from "@/views/components/ComponentProductSearchForm";

import { integerHelper, objectHelper, stringHelper } from "@/helpers";
import { NOTIFY_TYPE, SEARCH_BY } from "@/helpers/constants";
import { STORE } from "@/store/constants";

export default {
  name: "OrderBasket",

  metaInfo() {
    return {
      title: this.$t("r_page_orderBasket")
    };
  },

  components: { Layout, ProductSearchForm },

  data() {
    return {
      searchCriteria: {
        search: "",
        searchBy: SEARCH_BY.partNumber
      },
      orderBasket: []
    };
  },

  computed: {
    allPartsQuantityPositive() {
      return this.orderBasket.filter(part =>
        integerHelper.isIntegerPositive(stringHelper.trim(part.qtyOrdered))
      );
    },

    canCheckout() {
      return this.orderBasket.length <= 50;
    },

    columns() {
      let cols = [
        {
          key: "vendorPartNumber",
          label: this.$t("r_vendorPartNumber")
        },
        {
          key: "partNumber",
          label: this.$t("r_partNumber")
        },
        {
          key: "description",
          label: this.$t("r_description")
        }
      ];

      if (this.displayAdas) {
        cols.push({
          key: "isAdasAvailable",
          label: this.$t("r_adas"),
          class: "text-center"
        });
      }

      cols.push({
        key: "branchNumber",
        label: this.$t("r_branchNumber")
      });

      const user = this.$store.state.user.authResult.user;

      if (user.showPriceNet) {
        cols.push({
          key: "netPrice",
          label: this.$t("r_netPrice"),
          class: "text-right"
        });
      }

      cols = [
        ...cols,
        {
          key: "qtyOrdered",
          label: this.$t("r_qtyReq")
        },
        {
          key: "linePO",
          label: this.$t("r_linePO")
        }
      ];

      return cols;
    },

    customerId() {
      return this.$store.getters[STORE.user.customer].customerID;
    },

    displayAdas() {
      const authResult = this.$store.state.user.authResult;

      return (
        authResult.isAdasCustomer &&
        authResult.adasBranchNumbers.includes(
          this.$store.state.part.branchNumber
        )
      );
    },

    orderBasketCach() {
      return this.$store.state.orderBasket.orderBasket;
    },

    orderBasketChanged() {
      return this.orderBasket.some(
        (part, index) =>
          !objectHelper.isEqual(
            part,
            this.$store.state.orderBasket.orderBasket[index]
          )
      );
    }
  },

  watch: {
    orderBasketCach() {
      this.fetchData();
    }
  },

  created() {
    this.$store.dispatch(STORE.orderBasket.get, this.customerId);
  },

  methods: {
    btnSearchClick() {
      const query =
        this.searchCriteria.searchBy === SEARCH_BY.partNumber
          ? { partNumber: this.searchCriteria.search }
          : { keyword: this.searchCriteria.search };

      this.$router.push({
        path: this.$t("r_page_productSearch_url"),
        query: {
          branchNumber: this.$store.state.part.branchNumber,
          page: 1,
          ...query
        }
      });
    },

    async cancelOrder() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        this.$t("r_orderBasketCancelConfirm"),
        {
          headerBgVariant: "warning",
          cancelTitle: this.$t("r_no"),
          okTitle: this.$t("r_yes"),
          okVariant: "danger",
          title: this.$t("r_confirmation")
        }
      );

      if (confirm) {
        const success = await this.$store.dispatch(
          STORE.orderBasket.delete,
          this.customerId
        );

        if (success) {
          this.$_notify({
            type: NOTIFY_TYPE.success,
            title: this.$t("r_success"),
            message: this.$t("r_cancelOrderSuccess")
          });

          this.orderBasket = [];

          this.processOrder("cancelOrder");
        }
      }
    },

    checkoutOrder() {
      this.processOrder("checkoutOrder");

      this.$router.push(this.$t("r_page_orderCheckout_url"));
    },

    fetchData() {
      this.orderBasket = objectHelper.copy(this.orderBasketCach);
    },

    async updateOrder() {
      const orderBasketList = this.orderBasket.map(
        ({ id, qtyOrdered, linePO }) => ({
          id,
          qtyOrdered: integerHelper.isIntegerPositive(qtyOrdered)
            ? qtyOrdered
            : 0,
          linePO
        })
      );

      const command = {
        customerID: this.customerId,
        orderBasketList
      };

      const success = await this.$store.dispatch(
        STORE.orderBasket.update,
        command
      );

      if (success) {
        this.$_notify({
          type: NOTIFY_TYPE.success,
          title: this.$t("r_success"),
          message: this.$t("r_updateOrderSuccess")
        });

        this.processOrder("updateOrder");

        this.fetchData();
      }
    },

    // Google Analytics, using VueAnalytics
    processOrder(action) {
      this.$ga.event("orderBasket", "order", "action", action);
    }
  }
};
</script>

<template>
  <layout>
    <product-search-form v-model="searchCriteria" @click="btnSearchClick" />
    <hr />
    <br />
    <h2 class="title">{{ $t("r_page_orderBasket") }}</h2>
    <hr />
    <div v-if="orderBasket.length === 0" class="text-center">
      <small>{{ $t("r_empty_orderBasket") }}</small>
    </div>
    <div v-else>
      <b-alert :show="!canCheckout" variant="danger">
        {{ $t("r_orderBasketFull") }}
      </b-alert>
      <br />
      <b-table
        :fields="columns"
        :items="orderBasket"
        small
        class="table-vanfax"
      >
        <template #cell(isAdasAvailable)="data">
          <font-awesome-icon
            v-if="data.value"
            icon="check"
            class="text-green"
            style="font-size: 20px"
          />
        </template>
        <template #cell(netPrice)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(qtyOrdered)="data">
          <b-form-input
            v-model.number="orderBasket[data.index].qtyOrdered"
            type="text"
            maxlength="4"
            class="input-qty"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </template>
        <template #cell(linePO)="data">
          <b-form-input
            v-model.trim="orderBasket[data.index].linePO"
            type="text"
            maxlength="8"
            class="input-po"
          />
        </template>
      </b-table>
      <hr />
      <div class="text-center text-muted">
        <small v-html="$t('r_orderBasketHelpRemoveItem')" />
      </div>
      <div class="text-right">
        <button
          class="btn-blue-outline mt-3"
          :disabled="!orderBasketChanged"
          @click="updateOrder"
        >
          {{ $t("r_updateOrder") }}
        </button>
        <button class="btn-blue-outline mt-3 ml-2" @click="cancelOrder">
          {{ $t("r_cancelOrder") }}
        </button>
        <button
          class="btn-blue mt-3 ml-2"
          :disabled="orderBasketChanged || !canCheckout"
          @click="checkoutOrder"
        >
          {{ $t("r_checkoutOrder") }}
        </button>
      </div>
    </div>
  </layout>
</template>

<style scoped>
.input-qty {
  max-width: 50px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  display: inline-block;
}

.input-po {
  max-width: 150px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}
</style>
