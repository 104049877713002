import Vue from "vue";
import Vuex from "vuex";

import orderBasket from "./modules/order-basket";
import part from "./modules/part";
import user from "./modules/user";

import { STORE } from "./constants";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: { inMaintenance: false, nagsDisplayed: false },

  modules: { orderBasket, part, user },

  getters: {
    inMaintenance: state => {
      return state.inMaintenance || window.appSettings.inMaintenance;
    }
  },

  mutations: {
    setMaintenance: state => {
      state.inMaintenance = true;
    },

    setNagsDisplayed: (state, payload) => {
      state.nagsDisplayed = payload.nagsDisplayed;
    }
  },

  actions: {
    reset: ({ commit }) => {
      commit(STORE.part.reset);
      commit(STORE.orderBasket.reset);
    },

    setNagsDisplayed: ({ commit }, payload) => {
      commit("setNagsDisplayed", payload);
    }
  }
});

export default store;
