import Vue from "vue";

import { decimalHelper, stringHelper } from "@/helpers";

Vue.filter("currency", value => {
  return decimalHelper.isDecimal(value) ? "$" + value.toFixed(2) : "";
});

Vue.filter("date", value => {
  return stringHelper.isNull(value) ? "N/A" : value.substr(0, 10);
});

Vue.filter("uppercase", value => {
  return stringHelper.isNull(value) ? "" : value.toUpperCase();
});
