<script>
import Layout from "@/views/layouts/LayoutAdminSession";
import TablePagination from "../components/TablePagination";

import { orderService } from "@/services";
import { arrayHelper } from "@/helpers";
import { STORE } from "@/store/constants";

export default {
  name: "CurrentOrders",

  metaInfo() {
    return {
      title: this.$t("r_page_currentOrders")
    };
  },

  components: { Layout, TablePagination },

  data() {
    return {
      currentPage: 1,
      currentOrders: [],
      filter: null,
      perPage: 50,
      totalRows: 0
    };
  },

  computed: {
    columns() {
      return [
        {
          key: "branchNumber",
          label: this.$t("r_branchNumber")
        },
        {
          key: "orderNumber",
          label: this.$t("r_orderNumber")
        },
        {
          key: "orderDate",
          label: this.$t("r_orderDate")
        },
        {
          key: "purchaseOrder",
          label: this.$t("r_poNumber")
        },
        {
          key: "pickingNumber",
          label: this.$t("r_pickingNumber")
        },
        {
          key: "invoiceNumber",
          label: this.$t("r_invoiceNumber")
        }
      ];
    }
  },

  created() {
    const query = {
      customerID: this.$store.getters[STORE.user.customer].customerID,
      daysBefore: window.appSettings.numberOfDaysForOrderHistory
    };

    orderService.getInfo(query).then(result => {
      if (!arrayHelper.isNull(result)) {
        this.currentOrders = result

          .map(order => ({
            ...order,
            orderDate: order.orderDate.substr(0, 10)
          }))
          .sort(
            (a, b) =>
              new Date(b.orderDate) - new Date(a.orderDate) ||
              b.orderNumber.localeCompare(a.orderNumber)
          );
      }

      this.totalRows = this.currentOrders.length;
    });
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    pageChanged(page) {
      this.currentPage = page;
    },

    perPageChanged(value) {
      this.perPage = value;
    }
  }
};
</script>

<template>
  <layout>
    <h2 class="title">{{ $t("r_page_currentOrders") }}</h2>
    <hr />
    <div v-if="currentOrders.length === 0" class="text-center">
      <br />
      <small>{{ $t("r_noOrderFound") }}</small>
    </div>
    <div v-else>
      <b-row>
        <b-col md="6" lg="5">
          <b-form-group
            :label="$t('r_filter')"
            label-cols-md="3"
            label-cols-lg="2"
            label-align-md="right"
            label-size="sm"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                :placeholder="$t('r_filter_placeholder')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  :title="$t('r_filter_clear')"
                  @click="filter = ''"
                >
                  <font-awesome-icon icon="times" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :fields="columns"
        :items="currentOrders"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        small
        class="table-vanfax"
        @filtered="onFiltered"
      >
        <template #cell(orderNumber)="data">
          <router-link
            :to="{
              path: $t('r_page_orderDetails_url'),
              query: {
                orderNumber: data.value,
                branchNumber: data.item.branchNumber
              }
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
      </b-table>
      <table-pagination
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        @change="pageChanged"
        @per-page-change="perPageChanged"
      />
      <hr />
    </div>
    <br />
  </layout>
</template>
