import { httpHelper } from "@/helpers";

const controller = "/reward/";

export const rewardService = {
  async add(command) {
    try {
      const response = await httpHelper.post(controller, command);

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Reward.Register");

      return error;
    }
  }
};
