import { userService } from "@/services";

import { errorHelper, objectHelper, sessionHelper } from "@/helpers";
import { STORE } from "../constants";

export default {
  namespaced: true,

  state: {
    authResult: null,
    csrCustomer: null
  },

  getters: {
    customer: state =>
      state.authResult.user.isCSR
        ? state.csrCustomer
        : state.authResult.customer,

    isAuthenticated: state =>
      !objectHelper.isNull(state.authResult) &&
      state.authResult.isAuthenticated,

    requirePasswordChangeOrAcceptTerms: (state, getters) =>
      getters.isAuthenticated &&
      (state.authResult.user.nbDaysBeforePasswordChange === 0 ||
        state.authResult.user.requireTermsAndConditions ||
        state.authResult.user.requireTermsOfUse)
  },

  mutations: {
    logout: state => {
      state.authResult = null;
      state.csrCustomer = null;
    },

    pointsRegister: stat => {
      stat.authResult.isCustomerRewardsExists = true;
    },

    set: (state, payload) => {
      state.authResult = payload.authResult;

      sessionHelper.setUser(payload.authResult);
    },

    resetNbDaysBeforePasswordChange: state => {
      state.authResult.user.nbDaysBeforePasswordChange = Number.MAX_VALUE;
    },

    resetRequireTermsAndConditions: state => {
      state.authResult.user.requireTermsAndConditions = false;
    },

    resetRequireTermsOfUse: state => {
      state.authResult.user.requireTermsOfUse = false;
    },

    setCsrCustomer: (state, payload) => {
      state.csrCustomer = payload.csrCustomer;
    },

    setShipToList: (state, payload) => {
      state.authResult.shipToList = payload.shipToList;
    }
  },

  actions: {
    logout: ({ dispatch, commit }) => {
      commit("logout");
      dispatch(STORE.mainStore.reset, null, { root: true });
      dispatch(
        STORE.mainStore.setNagsDisplayed,
        { nagsDisplayed: false },
        { root: true }
      );

      sessionHelper.removeUser();
    },

    updateLastTermsOfUseAcceptDate: async ({ commit, state }) => {
      const command = {
        username: state.authResult.username,
        userID: state.authResult.user.userID,
        customerID: state.authResult.customer.customerID
      };

      const result = await userService.updateLastTermsOfUseAcceptDate(command);

      if (!errorHelper.isError(result)) {
        commit("resetRequireTermsOfUse");

        return true;
      } else {
        return false;
      }
    },

    updateLastTermsAndConditionsAcceptDate: async ({ commit, state }) => {
      const command = {
        username: state.authResult.username,
        userID: state.authResult.user.userID,
        customerID: state.authResult.customer.customerID
      };

      const result = await userService.updateLastTermsAndConditionsAcceptDate(
        command
      );

      if (!errorHelper.isError(result)) {
        commit("resetRequireTermsAndConditions");

        return true;
      } else {
        return false;
      }
    }
  }
};
