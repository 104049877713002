import { httpHelper } from "@/helpers";

export const nagsService = {
  getVehicleYears,
  getVehicleMakes,
  getVehicleModels,
  getVehicleStyles,
  getVehiclePartsDescription
};

const controller = "/nags/";

async function getVehicleYears() {
  try {
    const response = await httpHelper.get(controller + "years");

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call NAGS.Years");

    return error;
  }
}

async function getVehicleMakes(year) {
  try {
    const response = await httpHelper.get(controller + "makes", {
      params: { year }
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call NAGS.Makes");

    return error;
  }
}

async function getVehicleModels(year, make) {
  try {
    const response = await httpHelper.get(controller + "models", {
      params: { year, make }
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call NAGS.Models");

    return error;
  }
}

async function getVehicleStyles(year, make, model) {
  try {
    const response = await httpHelper.get(controller + "styles", {
      params: { year, make, model }
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call NAGS.Styles");

    return error;
  }
}

async function getVehiclePartsDescription(query) {
  try {
    const response = await httpHelper.get(controller + "partsdesc", {
      params: query
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call.GetVehiclePartsDescription");

    return error;
  }
}
