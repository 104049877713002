import { partService } from "@/services";

import { SEALER_BY } from "@/helpers/constants";
import { errorHelper, objectHelper } from "@/helpers";

export default {
  namespaced: true,

  state: {
    parts: [],
    partsQuery: {},
    partsDescription: [],
    partsDescriptionKeyword: null,
    partsDow: [],
    partsDowBranchNumber: null,
    partsSika: [],
    partsSikaBranchNumber: null,
    branchNumber: null
  },

  mutations: {
    reset: state => {
      state.parts = [];
      state.partsQuery = {};
      state.partsDescription = [];
      state.partsDescriptionKeyword = null;
      state.partsDow = [];
      state.partsDowBranchNumber = null;
      state.partsSika = [];
      state.partsSikaBranchNumber = null;
      state.branchNumber = null;
    },

    resetCache: state => {
      state.parts = [];
      state.partsQuery = {};
      state.partsDow = [];
      state.partsDowBranchNumber = null;
      state.partsSika = [];
      state.partsSikaBranchNumber = null;
    },

    setParts: (state, payload) => (state.parts = payload.parts),

    setPartsQuery: (state, payload) => (state.partsQuery = payload.query),

    setPartsDescription: (state, payload) =>
      (state.partsDescription = payload.parts),

    setPartsDescriptionKeyword: (state, payload) =>
      (state.partsDescriptionKeyword = payload.keyword),

    setPartsDow: (state, payload) => (state.partsDow = payload.parts),

    setPartsDowBranchNumber: (state, payload) =>
      (state.partsDowBranchNumber = payload.branchNumber),

    setPartsSika: (state, payload) => (state.partsSika = payload.parts),

    setPartsSikaBranchNumber: (state, payload) =>
      (state.partsSikaBranchNumber = payload.branchNumber),

    setBranchNumber: (state, payload) =>
      (state.branchNumber = payload.branchNumber)
  },

  actions: {
    getUrethanes: async ({ commit, state }, query) => {
      if (
        (query.dealer === SEALER_BY.sika &&
          query.branchNumber === state.partsSikaBranchNumber) ||
        (query.dealer === SEALER_BY.dow &&
          query.branchNumber === state.partsDowBranchNumber)
      ) {
        return Promise.resolve();
      } else {
        return partService.getUrethanes(query).then(parts => {
          if (!errorHelper.isError(parts)) {
            if (query.dealer === SEALER_BY.sika) {
              commit("setPartsSika", { parts });
              commit("setPartsSikaBranchNumber", {
                branchNumber: query.branchNumber
              });
            } else {
              commit("setPartsDow", { parts });
              commit("setPartsDowBranchNumber", {
                branchNumber: query.branchNumber
              });
            }
          }
        });
      }
    },

    searchParts: async ({ commit, state }, query) => {
      if (objectHelper.isEqual(query, state.partsQuery)) {
        return Promise.resolve();
      } else {
        return partService.searchParts(query).then(parts => {
          if (!errorHelper.isError(parts)) {
            commit("setParts", { parts });
            commit("setPartsQuery", { query });
          }
        });
      }
    },

    searchPartsDescription: async ({ commit, state }, query) => {
      if (query.keyword === state.partsDescriptionKeyword) {
        return Promise.resolve();
      } else {
        return partService.searchPartsDescription(query).then(parts => {
          if (!errorHelper.isError(parts)) {
            commit("setPartsDescription", { parts });
            commit("setPartsDescriptionKeyword", { keyword: query.keyword });
          }
        });
      }
    }
  }
};
