import Vue from "vue";
import Router from "vue-router";

import i18n from "./i18n";
import store from "./store";
import routes from "./routes";

import { STORE } from "./store/constants";
import { objectHelper } from "@/helpers";

Vue.use(Router);

const router = new Router({
  mode: "history",

  routes,

  scrollBehavior(to, _from, savedPosition) {
    return savedPosition
      ? savedPosition
      : to.path === i18n.t("r_page_productSearch_url")
      ? false
      : { x: 0, y: 0 };
  }
});

router.beforeEach((to, _from, next) => {
  const lang = to.path.indexOf("/fr/") !== -1 ? "fr" : "en";

  i18n.locale = lang;

  const daysBeforePasswordChange = store.getters[STORE.user.isAuthenticated]
    ? store.state.user.authResult.user.nbDaysBeforePasswordChange
    : 0;

  const changePasswordPage = store.getters[STORE.user.isAuthenticated]
    ? store.state.user.authResult.user.isManager
      ? i18n.t("r_page_changePasswordManager_url")
      : i18n.t("r_page_changePasswordEmployee_url")
    : "";

  const requireTermsOfUse =
    store.getters[STORE.user.isAuthenticated] &&
    store.state.user.authResult.user.requireTermsOfUse;

  const requireTermsAndConditions =
    store.getters[STORE.user.isAuthenticated] &&
    store.state.user.authResult.user.requireTermsAndConditions;

  const storeSelected =
    store.getters[STORE.user.isAuthenticated] &&
    (!store.state.user.authResult.user.isCSR ||
      !objectHelper.isNullObject(store.state.user.csrCustomer));

  if (to.path === i18n.t("r_page_maintenance_url")) {
    next();
  } else {
    if (store.getters.inMaintenance) {
      next(i18n.t("r_page_maintenance_url"));
    } else if (
      !store.getters[STORE.user.isAuthenticated] &&
      to.matched.some(record => record.meta.guest !== true)
    ) {
      next({
        path: i18n.t("r_page_login_url"),
        query: { url: to.path, query: to.query }
      });
    } else if (
      store.getters[STORE.user.isAuthenticated] &&
      requireTermsOfUse &&
      to.path !== i18n.t("r_page_termsOfUse_url")
    ) {
      next(i18n.t("r_page_termsOfUse_url"));
    } else if (
      store.getters[STORE.user.isAuthenticated] &&
      !requireTermsOfUse &&
      requireTermsAndConditions &&
      to.path !== i18n.t("r_page_termsAndConditions_url")
    ) {
      next(i18n.t("r_page_termsAndConditions_url"));
    } else if (
      store.getters[STORE.user.isAuthenticated] &&
      !requireTermsOfUse &&
      !requireTermsAndConditions &&
      daysBeforePasswordChange < 1 &&
      to.path !== changePasswordPage
    ) {
      next(changePasswordPage);
    } else if (
      store.getters[STORE.user.isAuthenticated] &&
      daysBeforePasswordChange > 0 &&
      !requireTermsOfUse &&
      !requireTermsAndConditions &&
      !storeSelected &&
      to.matched.some(record => record.meta.storeSelected === true)
    ) {
      next(i18n.t("r_page_selectStore_url"));
    } else {
      next();
    }
  }
});

export default router;
