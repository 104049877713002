export const FEEDBACK_TYPE = {
  comments: 0,
  credit: 1
};

export const NOTIFY_TYPE = {
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger"
};

export const SEALER_BY = {
  sika: "sika",
  dow: "dow"
};

export const SEARCH_BY = {
  partNumber: "p",
  keyword: "k"
};
