<script>
import { STORE } from "@/store/constants";
import { SEARCH_BY } from "@/helpers/constants";

export default {
  name: "ComponentProductSearchForm",

  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    authResult() {
      return this.$store.state.user.authResult;
    },

    branchNumber: {
      get() {
        return this.$store.state.part.branchNumber;
      },

      set(branchNumber) {
        this.$store.commit(STORE.part.setBranchNumber, { branchNumber });
      }
    },

    isCsr() {
      return this.authResult.user.isCSR;
    },

    isOrderBasketEmpty() {
      return this.$store.getters[STORE.orderBasket.isOrderBasketEmpty];
    },

    searchByEnum() {
      return SEARCH_BY;
    },

    warehouses() {
      const customer = this.$store.getters[STORE.user.customer];

      return customer.warehouses.map(warehouse => ({
        value: warehouse.number,
        text: `${warehouse.number} - ${warehouse.nameShort}`
      }));
    }
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    }
  },

  mounted() {
    this.$refs.search.focus();
  },

  methods: {
    branchNumberChanged() {
      this.$emit("branch-number-changed");
    },

    btnSearchClick() {
      this.$emit("click");
    }
  }
};
</script>

<template>
  <div>
    <h2 class="title">{{ $t("r_page_productSearch") }}</h2>
    <hr />

    <b-row class="panel-product-search-input">
      <b-col sm="auto" md="6" class="my-3">
        <br />
        <b-row>
          <b-col>
            <b-form-input
              ref="search"
              v-model.trim="value.search"
              :placeholder="$t('r_searchHereEllipsis')"
              type="text"
              maxlength="100"
              style="height: 53px"
              autofocus
              @keyup.enter="btnSearchClick"
            />
          </b-col>
          <b-col sm="auto" lg="4">
            <button
              class="btn-search btn-red"
              :disabled="!value.search"
              @click="btnSearchClick"
            >
              {{ $t("r_search") }}
            </button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="auto" md="3" class="my-3">
        <strong>{{ $t("r_searchBy") }}:</strong>
        <b-form-radio-group v-model="value.searchBy" stacked>
          <b-form-radio :value="searchByEnum.partNumber">
            {{ $t("r_partNumber") }}
          </b-form-radio>
          <b-form-radio :value="searchByEnum.keyword">
            {{ $t("r_keyword") }}
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
      <b-col
        v-if="!isCsr"
        id="branchNumberSelect"
        sm="auto"
        md="3"
        class="my-3"
      >
        <strong>{{ $t("r_warehouse") }}:</strong>
        <b-form-select
          v-model="branchNumber"
          :options="warehouses"
          style="height: 53px"
          :disabled="!isOrderBasketEmpty"
          @change="branchNumberChanged"
        />
        <b-popover
          target="branchNumberSelect"
          triggers="hover"
          placement="top"
          :disabled="isOrderBasketEmpty"
        >
          <span class="text-red">{{ $t("r_warehouseInfo") }}</span>
        </b-popover>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.panel-product-search-input {
  margin: 20px 0;
  background: #f5f5f5;
}
</style>
