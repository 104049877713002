<script>
import Layout from "@/views/layouts/LayoutAdmin";

export default {
  name: "LayoutAdminPublic",

  components: { Layout },

  methods: {
    // Google Analytics, using VueAnalytics
    selectPage(page) {
      this.$ga.event("layoutAdminPublic", "menu", "page", page);
    }
  }
};
</script>

<template>
  <layout>
    <template #slot-menu>
      <ul>
        <li>
          <router-link
            class="customer-login"
            :to="$t('r_page_login_url')"
            @click.native="selectPage('customerLogin')"
          >
            <span class="lockIcon"></span>
            {{ $t("r_page_customerLogin") }}
          </router-link>
        </li>
        <li>
          <a
            class="open-account"
            :href="$t('r_page_openAccount_url')"
            @click="selectPage('openAccount')"
          >
            <span class="accountIcon"></span>
            {{ $t("r_page_openAccount") }}
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            :href="$t('r_page_vanfaxAdvantages_url')"
            @click="selectPage('vanfaxAdvantages')"
          >
            {{ $t("r_page_vanfaxAdvantages") }}
          </a>
        </li>
        <li>
          <a
            :href="$t('r_page_rewardsProgram_url')"
            @click="selectPage('rewardsProgram')"
          >
            {{ $t("r_page_rewardsProgram") }}
          </a>
        </li>
        <li>
          <a
            :href="$t('r_page_productsCatalog_url')"
            @click="selectPage('productsCatalog')"
          >
            {{ $t("r_page_productsCatalog") }}
          </a>
        </li>
        <li>
          <a
            :href="$t('r_page_warehouseLocator_url')"
            @click="selectPage('warehouseLocator')"
          >
            {{ $t("r_page_warehouseLocator") }}
          </a>
        </li>
        <li>
          <a
            :href="$t('r_page_ourPromotions_url')"
            @click="selectPage('ourPromotions')"
          >
            {{ $t("r_page_ourPromotions") }}
          </a>
        </li>
        <li>
          <a
            :href="$t('r_page_newsletters_url')"
            @click="selectPage('newsletters')"
          >
            {{ $t("r_page_newsletters") }}
            <font-awesome-icon
              icon="rss"
              class="text-red ml-2 vf-beat"
              style="--fa-animation-iteration-count: 20"
            />
          </a>
        </li>
        <li>
          <a :href="$t('r_page_careers_url')" @click="selectPage('careers')">
            {{ $t("r_page_careers") }}
          </a>
        </li>
      </ul>
    </template>
    <template #slot-main>
      <slot />
    </template>
  </layout>
</template>
