export const STORE = {
  orderBasket: {
    add: "orderBasket/add",
    delete: "orderBasket/delete",
    get: "orderBasket/get",
    isOrderBasketEmpty: "orderBasket/isOrderBasketEmpty",
    reset: "orderBasket/reset",
    submit: "orderBasket/submit",
    update: "orderBasket/update"
  },

  part: {
    getUrethanes: "part/getUrethanes",
    reset: "part/reset",
    resetCache: "part/resetCache",
    searchParts: "part/searchParts",
    searchPartsDescription: "part/searchPartsDescription",
    setBranchNumber: "part/setBranchNumber"
  },

  mainStore: {
    reset: "reset",
    setMaintenance: "setMaintenance",
    setNagsDisplayed: "setNagsDisplayed"
  },

  user: {
    customer: "user/customer",
    isAuthenticated: "user/isAuthenticated",
    logout: "user/logout",
    pointsRegister: "user/pointsRegister",
    requirePasswordChangeOrAcceptTerms:
      "user/requirePasswordChangeOrAcceptTerms",
    resetNbDaysBeforePasswordChange: "user/resetNbDaysBeforePasswordChange",
    set: "user/set",
    setCsrCustomer: "user/setCsrCustomer",
    setShipToList: "user/setShipToList",
    updateLastTermsAndConditionsAcceptDate:
      "user/updateLastTermsAndConditionsAcceptDate",
    updateLastTermsOfUseAcceptDate: "user/updateLastTermsOfUseAcceptDate"
  }
};
