import { httpHelper } from "@/helpers";

const controller = "/orderbasket/";

export const orderBasketService = {
  async get(customerId) {
    try {
      const response = await httpHelper.get(controller, {
        params: { customerId }
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call OrderBasket.Get");

      return error;
    }
  },

  async add(command) {
    try {
      const response = await httpHelper.post(controller, command);

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call OrderBasket.Add");

      return error;
    }
  },

  async update(command) {
    try {
      const response = await httpHelper.put(controller, command);

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call OrderBasket.Update");

      return error;
    }
  },

  async delete(customerId) {
    try {
      const response = await httpHelper.delete(controller, {
        params: { customerId }
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call OrderBasket.Delete");

      return error;
    }
  }
};
