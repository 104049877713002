var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("r_page_partsOnOrder")))]),_c('hr'),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('br'),_c('small',[_vm._v(_vm._s(_vm.$t("r_noPartsOnOrder")))])]):_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6","lg":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('r_filter'),"label-cols-md":"3","label-cols-lg":"2","label-align-md":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('r_filter_placeholder')},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter,"title":_vm.$t('r_filter_clear')},on:{"click":function($event){_vm.filter = ''}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)],1)],1)],1)],1)],1),_c('b-table',{staticClass:"table-vanfax",attrs:{"fields":_vm.columns,"items":_vm.parts,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"small":""},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(vendorPartNumber)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            path: _vm.$t('r_page_productSearch_url'),
            query: {
              partNumber: data.value,
              branchNumber: data.item.branchNumber
            }
          }}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(partNumber)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            path: _vm.$t('r_page_productSearch_url'),
            query: {
              partNumber: data.value,
              branchNumber: data.item.branchNumber
            }
          }}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(orderNumber)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            path: _vm.$t('r_page_orderDetails_url'),
            query: {
              orderNumber: data.value,
              branchNumber: data.item.branchNumber
            }
          }}},[_vm._v(" "+_vm._s(data.value)+" ")])]}}])}),_c('table-pagination',{attrs:{"current-page":_vm.currentPage,"per-page":_vm.perPage,"total-rows":_vm.totalRows},on:{"change":_vm.pageChanged,"per-page-change":_vm.perPageChanged}}),_c('hr')],1),_c('br')])
}
var staticRenderFns = []

export { render, staticRenderFns }