<script>
import Layout from "@/views/layouts/LayoutAdminSession";
import { stringHelper } from "@/helpers";

export default {
  name: "OrderSubmitResult",

  metaInfo() {
    return {
      title: this.$t("r_page_orderSubmitResult")
    };
  },

  components: { Layout },

  computed: {
    backorder() {
      return this.$route.query.backorder;
    },

    branchNumber() {
      return this.$route.query.branchNumber;
    },

    orderNumber() {
      return this.$route.query.orderNumber;
    }
  },

  created() {
    if (stringHelper.isNull(this.orderNumber)) {
      this.$router.replace(this.$t("r_page_productSearch_url"));
    }
  }
};
</script>

<template>
  <layout>
    <h2 class="title">{{ $t("r_page_orderSubmitResult") }}</h2>
    <hr />
    <br />
    <b-row class="justify-content-center">
      <b-col sm="12" md="6" lg="4">
        <b-alert show class="panel-order-submit-result">
          <p class="title">
            <font-awesome-icon icon="check" class="text-green icon-font-size" />
            &nbsp;{{ $t("r_orderSubmitted") }}
          </p>
          <hr />
          <p class="secondary no-margin">
            {{ $t("r_orderNumber") }}:
            <router-link
              :to="{
                path: $t('r_page_orderDetails_url'),
                query: {
                  orderNumber,
                  branchNumber
                }
              }"
            >
              {{ branchNumber }}-{{ orderNumber }}
            </router-link>
          </p>
        </b-alert>
      </b-col>
      <b-col v-if="backorder === true" sm="12" md="6" lg="4">
        <b-alert show variant="warning">
          <p class="text-center">
            <span v-html="$t('r_submitOrderResultCode_50_title')" />
          </p>
          <hr />
          <p class="text-center">{{ $t("r_submitOrderResultCode_50_text") }}</p>
        </b-alert>
      </b-col>
    </b-row>
    <br />
    <br />
  </layout>
</template>

<style scoped>
.panel-order-submit-result {
  background: #f5f5f5;
  text-align: center;
  font-weight: bold;
}

.panel-order-submit-result .title {
  font-size: 18px;
}

.panel-order-submit-result .secondary {
  font-size: 14px;
}
</style>
