import Vue from "vue";
import { ApplicationInsights } from "@microsoft/applicationinsights-web-basic";

const instrumentationKey = window.appSettings.applicationInsightsKey;

const appInsights = new ApplicationInsights({ instrumentationKey });

const formatComponentName = (vm, includeFile) => {
  if (vm.$root === vm) {
    return "<Root>";
  }

  const options =
    typeof vm === "function" && vm.cid !== null
      ? vm.options
      : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm || {};

  let name = options.name || options._componentTag;

  const file = options.__file;

  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/);

    name = match && match[1];
  }

  return (
    (name ? "<" + classify(name) + ">" : "<Anonymous>") +
    (file && includeFile !== false ? " at " + file : "")
  );
};

const classify = str =>
  str.replace(/(?:^|[-_])(\w)/g, c => c.toUpperCase()).replace(/[-_]/g, "");

const trackException = (error, vm, info) => {
  try {
    const properties = vm
      ? {
          errorInfo: info,
          component: formatComponentName(vm, true),
          props: vm.$options.propsData
        }
      : {};

    properties.stack = error?.stack;

    const exception = {
      name: `Microsoft.ApplicationInsights.${instrumentationKey}.Event`,
      baseType: "ExceptionData",
      baseData: {
        message: `Error: [Web.Vanfax] - ${error?.message}`,
        error,
        properties
      }
    };

    appInsights.core.track(exception);
    appInsights.flush();
  } catch {
    // left blank intentionally.
    // This will cause an infinite loop and cause browser to crash if we throw exception here.
  }
};

Vue.config.errorHandler = trackException;

window.onerror = (_msg, _url, _lineNo, _columnNo, error) => {
  trackException(error);

  return false;
};

export { trackException };
