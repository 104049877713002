<script>
// eslint-disable-next-line vue/require-name-property
export default {
  methods: {
    async $_confirmChngPwd() {
      const days =
        this.$store.state.user.authResult.user.nbDaysBeforePasswordChange;

      const confirmChngPwd = async () => {
        return await this.$bvModal.msgBoxConfirm(
          this.$t("r_passwordExpiration", { days }),
          {
            headerBgVariant: "warning",
            cancelTitle: this.$t("r_cancel"),
            okTitle: this.$t("r_passwordChange"),
            title: this.$t("r_warning")
          }
        );
      };

      const changePasswordPage = this.$store.state.user.authResult.user
        .isManager
        ? this.$t("r_page_changePasswordManager_url")
        : this.$t("r_page_changePasswordEmployee_url");

      if (days >= 1 && days <= 5) {
        const confirm = await confirmChngPwd();

        if (confirm) {
          this.$router.push(changePasswordPage);

          return true;
        }
      } else if (days < 1) {
        this.$router.push(changePasswordPage);

        return true;
      }

      return false;
    }
  }
};
</script>
