export const stringHelper = {
  formatEmail,
  hasAlphaLatine,
  hasNumeric,
  isEmail,
  isNull,
  isNullOrWhiteSpace,
  trim
};

function isNull(s) {
  return s === null || typeof s === "undefined";
}

function isNullOrWhiteSpace(s) {
  return (
    s === null || typeof s === "undefined" || s.toString().trim().length === 0
  );
}

function trim(s) {
  return this.isNull(s) ? null : s.toString().trim();
}

function hasNumeric(s) {
  let result = false;

  if (!this.isNull(s)) {
    for (let i = 0, len = s.length; i < len; i += 1) {
      let c = s[i];

      if (c >= "0" && c <= "9") {
        result = true;

        break;
      }
    }
  }

  return result;
}

function hasAlphaLatine(s) {
  let result = false;

  if (!this.isNull(s)) {
    for (let i = 0, len = s.length; i < len; i += 1) {
      const c = s[i];
      const alphabetLatine =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ";

      if (alphabetLatine.indexOf(c) !== -1) {
        result = true;

        break;
      }
    }
  }

  return result;
}

function isEmail(s) {
  let valid = !this.isNull(s);

  if (valid) {
    const matches =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
        s
      );

    valid = matches !== null;
  }

  return valid;
}

function toLower(s) {
  let result = null;

  if (!isNull(s)) {
    result = s.toLowerCase();
  }

  return result;
}

function formatEmail(s) {
  return toLower(this.trim(s));
}
