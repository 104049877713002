<script>
export default {
  name: "TablePagination",

  props: {
    currentPage: {
      type: Number,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    totalRows: {
      type: Number,
      required: true
    }
  },

  computed: {
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.$emit("change", value);
      }
    },

    perPageModel: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.$emit("per-page-change", value);
      }
    },

    pageOptions() {
      return [10, 50, 100];
    }
  }
};
</script>

<template>
  <b-row>
    <b-col md="auto" style="margin-top: 3px">
      <small>
        Total: <span class="font-weight-bolder">{{ totalRows }}</span>
      </small>
    </b-col>
    <b-col>
      <b-pagination
        v-model="currentPageModel"
        align="center"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        :next-text="$t('r_pagination_next')"
        :prev-text="$t('r_pagination_previous')"
        size="sm"
      />
    </b-col>
    <b-col md="auto">
      <b-form-group
        :label="$t('r_pagination_perPage')"
        label-cols-md="5"
        label-align-md="right"
        style="min-width: 145px"
        label-size="sm"
      >
        <b-form-select
          v-model="perPageModel"
          :options="pageOptions"
          size="sm"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
