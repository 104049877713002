export const randomHelper = { getRandomInt };

function getSeed() {
  let d = new Date();
  let seed = d.getMilliseconds();

  return seed;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max + 1);

  let result = Math.floor(Math.random(getSeed()) * (max - min)) + min;

  return result;
}
