export const integerHelper = { isIntegerPositive };

function isNull(s) {
  return s === null || typeof s === "undefined";
}

function isInteger(s) {
  let valid = !isNull(s);

  if (valid) {
    let matches = /^-?([0-9]\d*)$/.exec(s); //note: we accept '007' as integer here

    valid = matches !== null;
  }

  return valid;
}

function isIntegerPositive(s) {
  let valid = isInteger(s);

  if (valid) {
    let x = parseInt(s, 10);

    valid = x > 0;
  }

  return valid;
}
