export const arrayHelper = { isNull };

function isNull(array) {
  return (
    array === null ||
    array === undefined ||
    !(array instanceof Array) ||
    array.length === 0
  );
}
