import axios from "axios";

export const appVersionHelper = {
  async newAppVersionAvail() {
    if (process.env.NODE_ENV === "production") {
      try {
        const url = process.env.BASE_URL + "version.txt";

        const response = await axios.get(url);

        return response.data !== process.env.APP_VERSION;
      } catch {
        return false;
      }
    }

    return false;
  }
};
