import "./plugins/startup";
import "./plugins/application-insights";

import "es6-promise/auto";

import Vue from "vue";
import VueMeta from "vue-meta";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import notify from "./views/mixins/notify";

import "./helpers/filters";

import "./plugins/fontawesome";
import "./plugins/bootstrap-vue";
import "./plugins/loading-overlay";
import "./plugins/vue-analytics";

import "nprogress/nprogress.css";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

Vue.use(VueMeta);
Vue.mixin(notify);

Vue.config.productionTip = process.env.NODE_ENV !== "production";
Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.performance = process.env.NODE_ENV !== "production";

export default new Vue({
  name: "MainApp",
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
