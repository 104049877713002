<script>
import Layout from "@/views/layouts/LayoutAdminSession";

import { orderService } from "@/services";
import { errorHelper, stringHelper } from "@/helpers";
import { STORE } from "@/store/constants";

export default {
  name: "OrderDetails",

  metaInfo() {
    return {
      title: this.$t("r_page_orderDetails")
    };
  },

  components: { Layout },

  data() {
    return {
      loading: true,
      order: {}
    };
  },

  computed: {
    branchNumber() {
      return this.$route.query.branchNumber;
    },

    columns() {
      let cols = [
        {
          key: "line",
          label: this.$t("r_line"),
          class: "text-center"
        },
        {
          key: "partNumber",
          label: this.$t("r_partNumber")
        },
        {
          key: "vendorPartNumber",
          label: this.$t("r_vendorPartNumber")
        }
      ];

      if (this.user.showPriceNet) {
        cols.push({
          key: "netPrice",
          label: this.$t("r_netPrice"),
          class: "text-right"
        });
      }

      cols = [
        ...cols,
        {
          key: "qtyShip",
          label: this.$t("r_qty"),
          class: "text-right"
        },
        {
          key: "qtyBO",
          label: this.$t("r_bo"),
          class: "text-right"
        }
      ];

      if (this.user.showPriceNet) {
        cols = [
          ...cols,
          {
            key: "lineTotal",
            label: this.$t("r_lineTotal"),
            class: "text-right"
          },
          {
            key: "tax1",
            label: this.$t("r_tax1"),
            class: "text-right"
          },
          {
            key: "tax2",
            label: this.$t("r_tax2"),
            class: "text-right"
          }
        ];
      }

      cols.push({
        key: "linePO",
        label: this.$t("r_linePO")
      });

      return cols;
    },

    freight() {
      return this.order.order.freightCode === "P"
        ? this.$t("r_freight_prepaid")
        : this.$t("r_freight_collect");
    },

    shippingType() {
      return this.$i18n.locale === "fr"
        ? this.order.shippingType?.shippingTypeFr
        : this.order.shippingType?.shippingTypeEn;
    },

    user() {
      return this.$store.state.user.authResult.user;
    }
  },

  created() {
    const orderNumber = this.$route.query.orderNumber;

    if (
      stringHelper.isNull(orderNumber) ||
      stringHelper.isNull(this.branchNumber)
    ) {
      this.$router.replace(this.$t("r_page_currentOrders_url"));
    } else {
      const query = {
        customerID: this.$store.getters[STORE.user.customer].customerID,
        orderNumber,
        branchNumber: this.branchNumber
      };

      orderService.get(query).then(result => {
        if (!errorHelper.isError(result)) {
          this.order = result;
          this.loading = false;
        }
      });
    }
  }
};
</script>

<template>
  <layout>
    <h2 class="title">{{ $t("r_page_orderDetails") }}</h2>
    <hr />
    <div v-if="loading" class="text-center">
      <br />
      <small>{{ $t("r_noOrderFound") }}</small>
    </div>
    <div v-else>
      <div style="font-size: 13px">
        <table>
          <tbody>
            <tr>
              <td style="width: 15%">
                <strong>{{ $t("r_orderNumber") }}:</strong>
              </td>
              <td style="width: 18%">{{ order.order.orderNumber }}</td>
              <td style="width: 15%">
                <strong>{{ $t("r_poNumber") }}:</strong>
              </td>
              <td style="width: 18%">{{ order.order.purchaseOrder }}</td>
              <td style="width: 15%">
                <strong>{{ $t("r_orderPlacedBy") }}:</strong>
              </td>
              <td style="width: 19%">{{ order.order.orderPlacedBy }}</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("r_orderDate") }}:</strong>
              </td>
              <td>{{ order.order.orderDate | date }}</td>
              <td>
                <strong>{{ $t("r_orderStatus") }}:</strong>
              </td>
              <td>{{ order.order.orderStatus }}</td>
              <td>
                <strong>{{ $t("r_deliveryMethod") }}:</strong>
              </td>
              <td>{{ order.order.shippingRunName }}</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("r_pickingDate") }}:</strong>
              </td>
              <td>{{ order.order.pickDate | date }}</td>
              <td>
                <strong>{{ $t("r_shippingDate") }}:</strong>
              </td>
              <td>{{ order.order.shipDate | date }}</td>
              <td>
                <strong>{{ $t("r_shippingType") }}:</strong>
              </td>
              <td>{{ shippingType }}</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("r_branchNumber") }}:</strong>
              </td>
              <td>{{ order.order.branchNumber }}</td>
              <td>
                <strong>{{ $t("r_freight") }}:</strong>
              </td>
              <td>{{ freight }}</td>
              <td v-if="user.showPriceNet">
                <span>
                  <strong>{{ $t("r_totalPrice") }}:</strong>
                </span>
              </td>
              <td v-if="user.showPriceNet">
                <span>{{ order.order.orderTotal | currency }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <tbody>
            <tr>
              <td style="width: 33%; vertical-align: top">
                <span style="font-weight: bold"> {{ $t("r_shipFrom") }}: </span>
                <table>
                  <tbody>
                    <tr>
                      <td>{{ order.warehouseContact.fullName }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.warehouseContact.address1 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.warehouseContact.address2 }}</td>
                    </tr>
                    <tr>
                      <td>
                        {{ order.warehouseContact.city }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ order.warehouseContact.postalCode }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="width: 33%; vertical-align: top">
                <span style="font-weight: bold"> {{ $t("r_shipTo") }}: </span>
                <table>
                  <tbody>
                    <tr>
                      <td>{{ order.order.shipToName }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.shipToAddress1 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.shipToAddress2 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.shipToAddress3 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.shipToPostalCode }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="width: 34%; vertical-align: top">
                <span style="font-weight: bold">
                  {{ $t("r_billingAddress") }}:
                </span>
                <table>
                  <tbody>
                    <tr>
                      <td>{{ order.order.billToName }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.billToAddress1 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.billToAddress2 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.billToAddress3 }}</td>
                    </tr>
                    <tr>
                      <td>{{ order.order.billToPostalCode }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <strong>{{ $t("r_instructions") }}:</strong>
        <div v-html="order.comments"></div>
      </div>
      <br />
      <br />
      <b-table
        :fields="columns"
        :items="order.order.partDetails"
        small
        class="table-vanfax"
      >
        <template #cell(line)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(partNumber)="data">
          <router-link
            :to="{
              path: $t('r_page_productSearch_url'),
              query: { partNumber: data.value, branchNumber }
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(vendorPartNumber)="data">
          <router-link
            :to="{
              path: $t('r_page_productSearch_url'),
              query: { partNumber: data.value, branchNumber }
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(netPrice)="data">
          {{ (data.item.totalNetPrice / data.item.qtyShip) | currency }}
        </template>
        <template #cell(lineTotal)="data">
          {{
            (data.item.totalNetPrice + data.item.tax1 + data.item.tax2)
              | currency
          }}
        </template>
        <template #cell(tax1)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(tax2)="data">
          {{ data.value | currency }}
        </template>
      </b-table>
      <hr />
      <br />
      <div class="text-right no-print">
        <button class="btn-blue" onclick="window.print()">
          {{ $t("r_print_order") }}
        </button>
      </div>
    </div>
  </layout>
</template>
