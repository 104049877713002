<script>
import maintenance from "@/views/mixins/maintenance";

export default {
  name: "Home",

  mixins: [maintenance],

  computed: {
    appVersion() {
      return (
        "Version " +
        process.env.APP_VERSION.replace("T", " ")
          .replace("Z", "")
          .substring(0, 19)
      );
    }
  },

  methods: {
    // Google Analytics, using VueAnalytics
    selectLanguage(language) {
      this.$ga.event("home", "btnLanguageClick", "language", language);
    }
  }
};
</script>

<template>
  <div class="home">
    <div class="home-content">
      <img src="@/assets/images/vanfax.png" alt="Vanfax" :title="appVersion" />
      <p class="home-qsv">
        <span class="fr">Qualité, service et valeur</span>
        <span class="en" lang="en">Quality, service and value</span>
      </p>
      <p class="home-title">
        <span class="fr">
          VANFAX est l'une des plus importantes entreprises canadiennes de
          distribution de vitres automobiles.
        </span>
        <span class="en" lang="en">
          Vanfax is one of Canada's largest distributors of auto glass.
        </span>
      </p>
      <div class="home-separation"></div>
      <router-link
        :to="$t('r_page_login_url', 'fr')"
        class="btn-home btn-red mb-3"
        @click.native="selectLanguage('fr')"
      >
        Version française
      </router-link>
      <router-link
        :to="$t('r_page_login_url', 'en')"
        class="btn-home btn-white-outline mb-5"
        lang="en"
        @click.native="selectLanguage('en')"
      >
        English version
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.home {
  background-image: url("~@/assets/images/bkg-home.jpg");
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #063e67;
  font-family: "Open Sans", arial, sans-serif;
  color: #fff;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  height: 100vh;
  padding: 0;
  margin: 0;
  width: 100%;
}

.home:before {
  content: "";
  display: inline-block;
  margin-left: -0.25em;
  width: 0;
  vertical-align: middle;
}

.home-content {
  vertical-align: middle;
}

.home-content > p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  width: 100%;
}

.home-content > p.home-title {
  color: white;
  font-size: 18px;
}

.home-content > .home-title > .fr {
  display: inline-block;
  width: 100%;
}

.home-content > .home-title > .en {
  font-size: 16px;
  font-weight: 400;
  margin-top: 6px;
}

.home-content > .home-qsv {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 80px;
  margin-top: 26px;
  line-height: 0.9;
  font-family: "Rajdhani", arial, sans-serif;
  color: white;
}

.home-content > .home-qsv > .fr {
  display: inline-block;
  width: 100%;
}

.home-content > .home-qsv > .en {
  font-weight: 500;
  font-size: 30px;
}

.home-separation {
  position: relative;
  height: 80px;
  margin: 40px 0;
}

.home-separation:before {
  content: "";
  width: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.btn-home {
  width: 260px;
  margin: 0px 8px;
  display: inline-block;
}
</style>
