import { httpHelper } from "@/helpers";

const controller = "/feedback/";

export const feedbackService = {
  async submit(command) {
    try {
      const response = await httpHelper.post(controller, command);

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Feedback.Submit");

      return error;
    }
  }
};
