<script>
export default {
  name: "ComponentFooter",

  computed: {
    year() {
      const currentYear = new Date().getFullYear();

      return `2005–${currentYear}`;
    }
  },

  methods: {
    // Google Analytics, using VueAnalytics
    selectPage(page) {
      this.$ga.event("componentFooter", "selectPage", "page", page);
    }
  }
};
</script>

<template>
  <div id="footer-content">
    <div class="row">
      <div id="footer-title" class="col-7">
        <img
          src="@/assets/images/vanfax.png"
          width="150"
          height="auto"
          alt="Vanfax"
        />
        <span>{{ $t("r_footer_qsv") }}</span>
      </div>
      <div id="footer-contact" class="col">
        {{ $t("r_footer_contact") }}
      </div>
    </div>
    <div class="row">
      <div id="footer-belron" class="col-5">
        <a
          :href="$t('r_footer_copyright_url')"
          @click="selectPage('copyright')"
        >
          {{ $t("r_footer_copyright", { year }) }}
        </a>
      </div>
      <div id="footer-terms" class="col">
        <a
          :href="$t('r_footer_termsOfUse_url')"
          target="_blank"
          @click="selectPage('termsOfUse')"
        >
          {{ $t("r_footer_termsOfUse") }}
        </a>
        //
        <a
          :href="$t('r_footer_termsAndConditions_url')"
          target="_blank"
          @click="selectPage('termsAndConditions')"
        >
          {{ $t("r_footer_termsAndConditions") }}
        </a>
        //
        <a
          :href="$t('r_footer_siteMap_url')"
          target="_blank"
          @click="selectPage('siteMap')"
        >
          {{ $t("r_footer_siteMap") }}
        </a>
      </div>
    </div>
  </div>
</template>
