import { httpHelper } from "@/helpers";

export const partService = {
  getUrethanes,
  searchParts,
  searchPartsDescription
};

const controller = "/part/";

async function searchParts(query) {
  try {
    const response = await httpHelper.get(controller + "search", {
      params: query
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call Part.Search");

    return error;
  }
}

async function searchPartsDescription(query) {
  try {
    const response = await httpHelper.get(controller + "searchkeyword", {
      params: query
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call Part.searchkeyword");

    return error;
  }
}

async function getUrethanes(query) {
  try {
    const response = await httpHelper.get(controller + "geturethanes", {
      params: query
    });

    return response.data;
  } catch (error) {
    httpHelper.notifyApiError(error, "API Call Part.GetUrethanes");

    return error;
  }
}
