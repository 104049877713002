<script>
import flatPickr from "vue-flatpickr-component";

import Layout from "@/views/layouts/LayoutAdminSession";
import AdasRequestFrom from "@/views/components/ComponentAdasRequestForm";

import { STORE } from "@/store/constants";
import { NOTIFY_TYPE } from "@/helpers/constants";
import { arrayHelper, datetimeHelper, stringHelper } from "@/helpers";

export default {
  name: "OrderCheckout",

  metaInfo() {
    return {
      title: this.$t("r_page_orderCheckout_title")
    };
  },

  components: { Layout, flatPickr, AdasRequestFrom },

  data() {
    return {
      adasRequest: {
        adasOptIn: null,
        contactName: null,
        contactPhone: null,
        dateTimeRequested: null,
        vehicleMake: null,
        vehicleModel: null,
        vehicleYear: null
      },
      order: {
        appointmentDate: null,
        freightCode: "P",
        immediatePickup: false,
        instructions: null,
        jobDoneBy: null,
        jobId: null,
        orderPlacedBy: null,
        shipToId: null,
        shipTypeId: null
      },
      msgError: null
    };
  },

  computed: {
    authResult() {
      return this.$store.state.user.authResult;
    },

    branchNumber() {
      return this.$store.state.part.branchNumber;
    },

    calendarConfig() {
      return {
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        minDate: datetimeHelper.today(),
        time_24hr: true,
        wrap: true
      };
    },

    canSubmit() {
      return this.orderBasket.length <= 50;
    },

    columns() {
      let cols = [
        {
          key: "vendorPartNumber",
          label: this.$t("r_vendorPartNumberCheckout")
        },
        {
          key: "partNumber",
          label: this.$t("r_partNumber")
        },
        {
          key: "description",
          label: this.$t("r_description")
        }
      ];

      if (this.displayAdas) {
        cols.push({
          key: "isAdasAvailable",
          label: this.$t("r_adas"),
          class: "text-center"
        });
      }

      cols.push({
        key: "branchNumber",
        label: this.$t("r_branchNumber")
      });

      if (this.authResult.user.showPriceNet) {
        cols = [
          ...cols,
          {
            key: "netPrice",
            label: this.$t("r_netPrice"),
            class: "text-right"
          },
          {
            key: "gst",
            label: this.$t("r_tax1"),
            class: "text-right"
          },
          {
            key: "pst",
            label: this.$t("r_tax2"),
            class: "text-right"
          }
        ];
      }

      cols = [
        ...cols,
        {
          key: "qtyOrdered",
          label: this.$t("r_qtyReq"),
          class: "text-right"
        },
        {
          key: "linePO",
          label: this.$t("r_linePO")
        }
      ];

      return cols;
    },

    customer() {
      return this.$store.getters[STORE.user.customer];
    },

    displayAdas() {
      return (
        this.authResult.isAdasCustomer &&
        this.authResult.adasBranchNumbers.includes(this.branchNumber)
      );
    },

    displayAdasRequestFrom() {
      return (
        this.displayAdas && this.orderBasket.some(part => part.isAdasAvailable)
      );
    },

    freightList() {
      return [
        {
          value: "P",
          text: this.$t("r_freight_prepaid")
        },
        {
          value: "C",
          text: this.$t("r_freight_collect")
        }
      ];
    },

    isCsr() {
      return this.authResult.user.isCSR;
    },

    isStoreCorpo() {
      return this.isCsr || this.authResult.user.isStoreCorpo;
    },

    orderBasket() {
      return this.$store.state.orderBasket.orderBasket;
    },

    shippingTypes() {
      const warehouse = this.customer.warehouses.find(
        w => w.number === this.branchNumber
      );

      if (stringHelper.isNull(warehouse)) {
        return [];
      }

      const shipTypeIDs = warehouse.shipTypeIDs;
      const shippingTypes = this.authResult.shippingTypes;

      return arrayHelper.isNull(shippingTypes)
        ? []
        : shippingTypes
            .filter(shippingType =>
              shipTypeIDs.some(id => id === shippingType.shippingTypeID)
            )
            .map(shippingType => ({
              value: shippingType.shippingTypeID,
              text:
                this.$i18n.locale === "fr"
                  ? shippingType.shippingTypeFr
                  : shippingType.shippingTypeEn
            }));
    },

    shipToList() {
      const getFullAddress = ({
        storeName1,
        storeName2,
        address,
        city,
        postalCode
      }) => {
        const getAddressPart = part =>
          !stringHelper.isNullOrWhiteSpace(part) ? part.trim() + ", " : "";

        const fullAddress =
          getAddressPart(storeName1) +
          getAddressPart(storeName2) +
          getAddressPart(address) +
          getAddressPart(city) +
          getAddressPart(postalCode);

        return fullAddress.slice(0, -2);
      };

      const shipToList = this.authResult.shipToList;

      return arrayHelper.isNull(shipToList)
        ? []
        : shipToList.map(shipTo => ({
            value: shipTo.id,
            text: getFullAddress(shipTo)
          }));
    }
  },

  created() {
    this.$store.dispatch(STORE.orderBasket.get, this.customer.customerID);

    if (!arrayHelper.isNull(this.shipToList)) {
      this.order.shipToId = this.shipToList[0].value;
    }

    if (!this.isCsr && !arrayHelper.isNull(this.shippingTypes)) {
      this.order.shipTypeId = this.shippingTypes[0].value;
    }
  },

  methods: {
    async cancelOrder() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        this.$t("r_orderBasketCancelConfirm"),
        {
          headerBgVariant: "warning",
          cancelTitle: this.$t("r_no"),
          okTitle: this.$t("r_yes"),
          okVariant: "danger",
          title: this.$t("r_confirmation")
        }
      );

      if (confirm) {
        const success = await this.$store.dispatch(
          STORE.orderBasket.delete,
          this.customer.customerID
        );

        if (success) {
          this.$_notify({
            type: NOTIFY_TYPE.success,
            title: this.$t("r_success"),
            message: this.$t("r_cancelOrderSuccess")
          });

          this.processOrder("cancelOrder");
        }
      }
    },

    async submitOrder() {
      this.msgError = null;

      if (this.isStoreCorpo) {
        if (stringHelper.isNull(this.order.appointmentDate)) {
          this.msgError = this.$t("r_checkoutOrder_appointmentDate_validation");

          return;
        }

        if (stringHelper.isNull(this.order.jobDoneBy)) {
          this.msgError = this.$t("r_checkoutOrder_jobDoneBy_validation");

          return;
        }
      }

      if (this.isCsr) {
        if (stringHelper.isNullOrWhiteSpace(this.order.jobId)) {
          this.msgError = this.$t("r_checkoutOrder_jobId_validation");

          return;
        }
      }

      if (this.displayAdasRequestFrom) {
        if (this.adasRequest.adasOptIn === null) {
          this.msgError = this.$t("r_adas_optin_mandatory");

          return;
        }

        if (this.adasRequest.adasOptIn) {
          if (this.adasRequest.dateTimeRequested === null) {
            this.msgError = this.$t("r_adas_appointment_mandatory");

            return;
          }

          if (stringHelper.isNullOrWhiteSpace(this.adasRequest.contactName)) {
            this.msgError = this.$t("r_adas_name_mandatory");

            return;
          }

          if (stringHelper.isNullOrWhiteSpace(this.adasRequest.contactPhone)) {
            this.msgError = this.$t("r_adas_phone_mandatory");

            return;
          }
        }
      }

      const shipVia = this.isCsr
        ? this.order.immediatePickup
          ? 3
          : -1
        : this.order.shipTypeId;

      if (shipVia === null) {
        this.msgError = this.$t("r_checkoutOrder_shippingType_validation");

        return;
      }

      const command = {
        customerID: this.customer.customerID,
        shipToId: this.order.shipToId,
        appointment: datetimeHelper.toIsoFormat(this.order.appointmentDate),
        jobDoneBy: this.order.jobDoneBy,
        purchaseOrder: this.order.jobId,
        shipVia: shipVia.toString(),
        freightCode: this.order.freightCode,
        placedByName: this.order.orderPlacedBy,
        specialInstructions: this.order.instructions,
        adasOptIn: this.adasRequest.adasOptIn,
        adasDateTimeRequested: this.adasRequest.adasOptIn
          ? datetimeHelper.toIsoFormat(this.adasRequest.dateTimeRequested)
          : null,
        adasContactName: this.adasRequest.contactName,
        adasContactPhone: this.adasRequest.contactPhone,
        adasVehicleMake: this.adasRequest.vehicleMake,
        adasVehicleModel: this.adasRequest.vehicleModel,
        adasVehicleYear: this.adasRequest.vehicleYear
      };

      const result = await this.$store.dispatch(
        STORE.orderBasket.submit,
        command
      );

      if (result !== null) {
        if (result.resultCode === 0 || result.resultCode === 50) {
          this.$router.push({
            path: this.$t("r_page_orderSubmitResult_url"),
            query: {
              orderNumber: result.orderNumber,
              branchNumber: this.branchNumber,
              backorder: result.resultCode === 50
            }
          });

          this.processOrder("submitOrder");
        } else {
          const resultCode = result.resultCode.toString().padStart(2, 0);

          await this.$bvModal.msgBoxOk(
            this.$t(`r_submitOrderResultCode_${resultCode}_text`),
            {
              headerBgVariant: "warning",
              okVariant: "danger",
              title: this.$t("r_error")
            }
          );

          if (result.resultCode === 98) {
            this.$store.commit(STORE.orderBasket.reset);
            this.$store.commit(STORE.part.resetCache);
            this.$router.push(this.$t("r_page_currentOrders_url"));
          }
        }
      }
    },

    // Google Analytics, using VueAnalytics
    processOrder(action) {
      this.$ga.event("orderCheckout", "order", "action", action);
    }
  }
};
</script>

<template>
  <layout>
    <h2 class="title">{{ $t("r_page_orderCheckout_title") }}</h2>
    <hr />
    <div v-if="orderBasket.length === 0" class="text-center">
      <small>{{ $t("r_empty_orderBasket") }}</small>
    </div>
    <div v-else>
      <b-alert :show="!canSubmit" variant="danger">
        {{ $t("r_orderBasketFull") }}
      </b-alert>
      <b-alert :show="msgError !== null" variant="danger">
        <font-awesome-icon icon="exclamation-circle" /> {{ msgError }}
      </b-alert>
      <h3 class="no-margin">{{ $t("r_shippingInformation") }}:</h3>

      <div class="panel-shipping-information">
        <b-row>
          <b-col md="2">{{ $t("r_deliverTo") }}:</b-col>
          <b-col md="10">
            <b-form-select
              v-model="order.shipToId"
              :options="shipToList"
              autofocus
            />
          </b-col>
        </b-row>
        <b-row v-if="isStoreCorpo">
          <b-col md="2">{{ $t("r_appointment") }}:</b-col>
          <b-col md="4">
            <b-input-group>
              <flat-pickr
                v-model="order.appointmentDate"
                :config="calendarConfig"
                class="form-control"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" data-toggle>
                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="2">{{ $t("r_jobDoneBy") }}:</b-col>
          <b-col md="4">
            <b-form-radio-group v-model="order.jobDoneBy">
              <b-form-radio value="Service Center">
                {{ $t("r_jobDoneBySC") }}
              </b-form-radio>
              <b-form-radio value="Mobile">
                {{ $t("r_jobDoneByMobile") }}
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">{{ $t("r_shippingType") }}:</b-col>
          <b-col md="4">
            <b-form-checkbox v-if="isCsr" v-model="order.immediatePickup">
              {{ $t("r_immediate_pickup") }}
            </b-form-checkbox>
            <b-form-select
              v-else
              v-model="order.shipTypeId"
              :options="shippingTypes"
            />
          </b-col>
          <b-col md="2">
            {{ isStoreCorpo ? $t("r_jobID") : $t("r_jobID_ISP") }}:
          </b-col>
          <b-col md="4">
            <b-form-input
              v-model.trim="order.jobId"
              type="text"
              maxlength="14"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2"></b-col>
          <b-col md="4">
            <b-form-select v-model="order.freightCode" :options="freightList" />
          </b-col>
          <b-col md="2">{{ $t("r_orderPlacedBy") }}:</b-col>
          <b-col md="4">
            <b-form-input
              v-model.trim="order.orderPlacedBy"
              type="text"
              maxlength="10"
            />
          </b-col>
        </b-row>
      </div>
      <br />
      <h3>{{ $t("r_specialInstructions") }}:</h3>
      <b-row>
        <b-col>
          <b-form-textarea
            v-model.trim="order.instructions"
            rows="3"
            cols="20"
            class="form-control"
            maxlength="110"
            :placeholder="$t('r_instructionsEllipsis')"
          />
          <p class="text-right text-muted">
            <small>{{ $t("r_checkoutOrder_max_instructions") }}</small>
          </p>
        </b-col>
      </b-row>
      <div class="text-center">
        <small>{{ $t("r_pleaseContinueToCall") }}</small>
      </div>
      <adas-request-from
        v-if="displayAdasRequestFrom"
        :adas-request="adasRequest"
      />
      <hr />
      <h2 class="title">{{ $t("r_page_orderBasket") }}</h2>
      <b-table
        :fields="columns"
        :items="orderBasket"
        small
        class="table-vanfax"
      >
        <template #cell(isAdasAvailable)="data">
          <font-awesome-icon
            v-if="data.value"
            icon="check"
            class="text-green"
            style="font-size: 20px"
          />
        </template>
        <template #cell(netPrice)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(gst)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(pst)="data">
          {{ data.value | currency }}
        </template>
      </b-table>
      <hr />
      <div class="text-right">
        <button class="btn-blue-outline mt-2" @click="cancelOrder">
          {{ $t("r_cancelOrder") }}
        </button>
        <router-link
          :to="$t('r_page_orderBasket_url')"
          class="btn-blue-outline mt-2 ml-2"
          @click.native="processOrder('modifyOrder')"
        >
          {{ $t("r_modifyOrder") }}
        </router-link>
        <button
          class="btn-red mt-2 ml-2"
          :disabled="!canSubmit"
          @click="submitOrder"
        >
          {{ $t("r_submitOrder") }}
        </button>
      </div>
    </div>
  </layout>
</template>

<style scoped>
.panel-shipping-information > div > div {
  padding: 10px 20px;
  vertical-align: middle;
  line-height: 30px;
}
</style>
