<script>
import flatPickr from "vue-flatpickr-component";

import { datetimeHelper } from "@/helpers";

export default {
  name: "ComponentAdasRequestForm",

  components: { flatPickr },

  props: {
    adasRequest: {
      type: Object,
      required: true
    }
  },

  computed: {
    calendarConfig() {
      return {
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        minDate: this.minDate,
        time_24hr: true,
        wrap: true
      };
    },

    maxYear() {
      return new Date().getFullYear() + 1;
    },

    minDate() {
      const minDate = datetimeHelper.addDays(2);

      return new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate(),
        10,
        0,
        0
      );
    }
  }
};
</script>

<template>
  <div>
    <hr />
    <b-card class="text-center" border-variant="danger">
      <font-awesome-icon
        icon="exclamation-triangle"
        class="text-red"
        style="font-size: 20px"
      />
      {{ $t("r_adas_question") }}
    </b-card>
    <div class="text-center mt-3">
      {{ $t("r_adas_optin") }}
    </div>
    <b-form-radio-group
      v-model="adasRequest.adasOptIn"
      class="text-center mt-3"
    >
      <b-form-radio :value="true">
        {{ $t("r_yes") }}
      </b-form-radio>
      <b-form-radio :value="false">
        {{ $t("r_no") }}
      </b-form-radio>
    </b-form-radio-group>
    <div v-if="adasRequest.adasOptIn">
      <hr />
      <div class="text-center mb-3" v-html="$t('r_adas_intro')"></div>
      <b-row>
        <b-col lg="6">
          <b-card :header="$t('r_adas_appointment_title')">
            <b-form-group
              label-cols-md="4"
              label-cols-lg="7"
              :label="$t('r_adas_appointment_label')"
              label-for="dateTimeRequested"
            >
              <b-input-group id="dateTimeRequested">
                <flat-pickr
                  v-model="adasRequest.dateTimeRequested"
                  :config="calendarConfig"
                  class="form-control"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" data-toggle>
                    <font-awesome-icon :icon="['far', 'calendar-alt']" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              label-cols-lg="7"
              :label="$t('r_adas_name_label')"
              label-for="contactName"
            >
              <b-form-input
                id="contactName"
                v-model.trim="adasRequest.contactName"
                maxlength="100"
              />
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              label-cols-lg="7"
              :label="$t('r_adas_phone_label')"
              label-for="contactPhone"
            >
              <b-form-input
                id="contactPhone"
                v-model.trim="adasRequest.contactPhone"
                maxlength="50"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col lg="6" class="mt-md-3 mt-lg-0">
          <b-card :header="$t('r_adas_vehicle_title')">
            <b-form-group
              label-cols="4"
              :label="$t('r_adas_make_label')"
              label-for="vehicleMake"
            >
              <b-form-input
                id="vehicleMake"
                v-model.trim="adasRequest.vehicleMake"
                maxlength="50"
              />
            </b-form-group>
            <b-form-group
              label-cols="4"
              :label="$t('r_adas_model_label')"
              label-for="vehicleModel"
            >
              <b-form-input
                id="vehicleModel"
                v-model.trim="adasRequest.vehicleModel"
                maxlength="50"
              />
            </b-form-group>
            <b-form-group
              label-cols="4"
              :label="$t('r_adas_year_label')"
              label-for="vehicleYear"
            >
              <b-form-input
                id="vehicleYear"
                v-model.number="adasRequest.vehicleYear"
                type="number"
                min="1980"
                :max="maxYear"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
