import { arrayHelper, errorHelper } from "@/helpers";
import { orderBasketService, orderService } from "@/services";
import { STORE } from "../constants";

export default {
  namespaced: true,

  state: {
    orderBasket: []
  },

  getters: {
    isOrderBasketEmpty: state => arrayHelper.isNull(state.orderBasket)
  },

  mutations: {
    reset: state => (state.orderBasket = []),

    setOrderBasket: (state, payload) =>
      (state.orderBasket = payload.orderBasket)
  },

  actions: {
    add: async ({ commit }, command) => {
      return orderBasketService.add(command).then(orderBasket => {
        if (!errorHelper.isError(orderBasket)) {
          commit("setOrderBasket", { orderBasket });

          return true;
        } else {
          return false;
        }
      });
    },

    submit: async ({ commit }, command) => {
      return orderService.add(command).then(result => {
        if (!errorHelper.isError(result)) {
          if (result.resultCode === 0 || result.resultCode === 50) {
            commit("reset");
            commit(STORE.part.resetCache, null, { root: true });
          }

          return result;
        } else {
          return null;
        }
      });
    },

    delete: async ({ commit }, customerId) => {
      return orderBasketService.delete(customerId).then(result => {
        if (!errorHelper.isError(result)) {
          commit("reset");
          commit(STORE.part.resetCache, null, { root: true });

          return true;
        } else {
          return false;
        }
      });
    },

    get: async ({ commit }, customerId) => {
      commit("reset");

      return orderBasketService.get(customerId).then(orderBasket => {
        if (!errorHelper.isError(orderBasket)) {
          commit("setOrderBasket", { orderBasket });

          return true;
        } else {
          return false;
        }
      });
    },

    update: async ({ commit }, command) => {
      return orderBasketService.update(command).then(orderBasket => {
        if (!errorHelper.isError(orderBasket)) {
          commit("setOrderBasket", { orderBasket });

          return true;
        } else {
          return false;
        }
      });
    }
  }
};
