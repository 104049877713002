import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faCheck,
  faCog,
  faExclamationCircle,
  faExclamationTriangle,
  faImage,
  faSignOutAlt,
  faRss,
  faTimes,
  faTools
} from "@fortawesome/free-solid-svg-icons";

import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

library.add(
  faCalendarAlt,
  faCheck,
  faCog,
  faExclamationCircle,
  faExclamationTriangle,
  faImage,
  faSignOutAlt,
  faRss,
  faTimes,
  faTools
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
