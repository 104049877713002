<script>
import Layout from "@/views/layouts/LayoutAdminSession";

import { arrayHelper } from "@/helpers";
import { nagsService } from "@/services";
import { STORE } from "@/store/constants";

export default {
  name: "NagsCatalogue",

  metaInfo() {
    return {
      title: this.$t("r_page_nagsCatalog")
    };
  },

  components: { Layout },

  data() {
    return {
      year: null,
      years: [],
      make: null,
      makes: [],
      model: null,
      models: [],
      carId: null,
      stylescarid: [],
      parts: null
    };
  },

  computed: {
    columns() {
      return [
        {
          key: "checked",
          label: "",
          tdClass: "narrow"
        },
        {
          key: "partNumber",
          label: this.$t("r_partNumber")
        },
        {
          key: "description",
          label: this.$t("r_description")
        }
      ];
    },

    partsChecked() {
      return this.parts.filter(part => part.checked);
    },

    styles() {
      return this.stylescarid.map(style => ({
        text: style.style,
        value: style.carId
      }));
    }
  },

  activated() {
    if (!this.$store.state.nagsDisplayed) {
      this.clearData();

      this.$store.dispatch(STORE.mainStore.setNagsDisplayed, {
        nagsDisplayed: true
      });

      nagsService.getVehicleYears().then(result => {
        if (!arrayHelper.isNull(result)) {
          this.years = result;
        }
      });
    }
  },

  methods: {
    clearData() {
      this.year = null;
      this.years = [];
      this.make = null;
      this.makes = [];
      this.model = null;
      this.models = [];
      this.carId = null;
      this.stylescarid = [];
      this.parts = null;
    },

    ddlYearOnChange() {
      this.make = null;
      this.makes = [];
      this.model = null;
      this.models = [];
      this.carId = null;
      this.stylescarid = [];
      this.parts = null;

      nagsService.getVehicleMakes(this.year).then(result => {
        if (!arrayHelper.isNull(result)) {
          this.makes = result;
        }
      });
    },

    ddlMakeOnChange() {
      this.model = null;
      this.models = [];
      this.carId = null;
      this.stylescarid = [];
      this.parts = null;

      nagsService.getVehicleModels(this.year, this.make).then(result => {
        if (!arrayHelper.isNull(result)) {
          this.models = result;
        }
      });
    },

    ddlModelOnChange() {
      this.carId = null;
      this.stylescarid = [];
      this.parts = null;

      nagsService
        .getVehicleStyles(this.year, this.make, this.model)
        .then(result => {
          if (!arrayHelper.isNull(result)) {
            this.stylescarid = result;
          }
        });
    },

    ddlStyleCarIdOnChange() {
      this.parts = null;

      const query = {
        customerID: this.$store.getters[STORE.user.customer].customerID,
        carId: this.carId
      };

      nagsService.getVehiclePartsDescription(query).then(result => {
        if (!arrayHelper.isNull(result)) {
          this.parts = result.map(part => ({ checked: false, ...part }));
        }
      });

      // Google Analytics, using VueAnalytics
      this.$ga.event("nagsCatalog", "ddlStyleCarIdOnChange", "year", this.year);
      this.$ga.event("nagsCatalog", "ddlStyleCarIdOnChange", "make", this.make);
      this.$ga.event(
        "nagsCatalog",
        "ddlStyleCarIdOnChange",
        "model",
        this.model
      );
      this.$ga.event(
        "nagsCatalog",
        "ddlStyleCarIdOnChange",
        "carId",
        this.carId
      );
    },

    btnFindClick() {
      const partNumberChecked = this.partsChecked.map(part => part.partNumber);
      const partNumbersText = partNumberChecked.join(",");
      const partNumbersTextEncoded = partNumbersText;

      this.$router.push({
        path: this.$t("r_page_productSearch_url"),
        query: { partNumber: partNumbersTextEncoded }
      });
    },

    // Google Analytics, using VueAnalytics
    clickResult(partNumber) {
      this.$ga.event("nagsCatalog", "results", "partNumber", partNumber);
    }
  }
};
</script>

<template>
  <layout>
    <h2 class="title">{{ $t("r_page_nagsCatalog") }}</h2>
    <hr />
    <h3>{{ $t("r_searchPartsFromAVehicle") }}:</h3>
    <div class="nags-info">
      <b-row>
        <b-col md="2" lg="1">
          {{ $t("r_year") }}
        </b-col>
        <b-col sm="auto" md="10" lg="4">
          <b-form-select
            v-model="year"
            autofocus
            :options="years"
            @change="ddlYearOnChange"
          >
            <template #first>
              <option :value="null" disabled>
                -- {{ $t("r_select_year_placeholder") }} --
              </option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="1">
          {{ $t("r_make") }}
        </b-col>
        <b-col sm="auto" md="10" lg="4">
          <b-form-select
            v-model="make"
            :options="makes"
            :disabled="!year"
            @change="ddlMakeOnChange"
          >
            <template #first>
              <option :value="null" disabled>
                -- {{ $t("r_select_make_placeholder") }} --
              </option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="1">
          {{ $t("r_model") }}
        </b-col>
        <b-col sm="auto" md="10" lg="4">
          <b-form-select
            v-model="model"
            :options="models"
            :disabled="!make"
            @change="ddlModelOnChange"
          >
            <template #first>
              <option :value="null" disabled>
                -- {{ $t("r_select_model_placeholder") }} --
              </option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="1">
          {{ $t("r_style") }}
        </b-col>
        <b-col sm="auto" md="10" lg="4">
          <b-form-select
            v-model="carId"
            :options="styles"
            :disabled="!model"
            @change="ddlStyleCarIdOnChange"
          >
            <template #first>
              <option :value="null" disabled>
                -- {{ $t("r_select_style_placeholder") }} --
              </option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </div>

    <br />
    <div v-if="parts !== null">
      <small>
        {{ parts.length }}
        {{ $t("r_resultsFound") }}
      </small>
      <hr />
      <br />
      <b-table :fields="columns" :items="parts" small class="table-vanfax">
        <template #cell(checked)="data">
          <b-form-checkbox v-model="parts[data.index].checked" />
        </template>
        <template #cell(partNumber)="data">
          <router-link
            :to="{
              path: $t('r_page_productSearch_url'),
              query: { partNumber: data.value }
            }"
            @click.native="clickResult(data.value)"
          >
            {{ data.value }}
          </router-link>
        </template>
      </b-table>
      <hr />
      <p class="text-right">
        <button
          class="btn-blue"
          :disabled="partsChecked.length === 0"
          @click="btnFindClick"
        >
          {{ $t("r_find") }}
        </button>
      </p>
    </div>
  </layout>
</template>

<style scoped>
.nags-info > .row > div {
  height: 50px;
  line-height: 50px;
}

.narrow {
  width: 60px;
}
</style>
