import { objectHelper } from "@/helpers";

export const sessionHelper = { getUser, setUser, removeUser };

function setUser(user) {
  const json = !objectHelper.isNull(user) ? JSON.stringify(user) : null;

  sessionStorage.setItem("user", json);
}

function getUser() {
  const json = sessionStorage.getItem("user");
  const user = !objectHelper.isNull(json) ? JSON.parse(json) : null;

  return user;
}

function removeUser() {
  sessionStorage.removeItem("user");
}
