import { httpHelper } from "@/helpers";

const controller = "/order/";

export const orderService = {
  async get(query) {
    try {
      const response = await httpHelper.get(controller, {
        params: query
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Order.Get");

      return error;
    }
  },

  async getInvoices(query) {
    try {
      const response = await httpHelper.get(controller + "invoices", {
        params: query
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Order.GetInfo");

      return error;
    }
  },

  async getInfo(query) {
    try {
      const response = await httpHelper.get(controller + "info", {
        params: query
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Order.GetInfo");

      return error;
    }
  },

  async add(command) {
    try {
      const response = await httpHelper.post(controller, command);

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Order.Add");

      return error;
    }
  }
};
