<script>
import Layout from "@/views/layouts/LayoutAdminPublic";
import ComponentBannerCatalog from "@/views/components/ComponentBannerCatalog";
import maintenance from "@/views/mixins/maintenance";
import passwordChangePopup from "@/views/mixins/passwordChangePopup";

import { userService } from "@/services";

import { STORE } from "@/store/constants";

import {
  browserHelper,
  errorHelper,
  objectHelper,
  sessionHelper,
  stringHelper
} from "@/helpers";

export default {
  name: "Login",

  components: { Layout, ComponentBannerCatalog },

  mixins: [maintenance, passwordChangePopup],

  metaInfo() {
    return {
      title: this.$t("r_login_customerLogin")
    };
  },

  data() {
    return {
      username: "",
      password: "",
      errorCode: 0
    };
  },

  computed: {
    errorMsg() {
      if (this.errorCode !== 0) {
        const errorCode = this.errorCode.toString().padStart(3, 0);

        return this.$t(`r_login_errorCode_${errorCode}_text`);
      } else {
        return "";
      }
    },

    isOldIe() {
      return browserHelper.isOldIe();
    },
    numberOfEmployees() {
      return window.appSettings.numberOfEmployees;
    },
    numberOfDc() {
      return window.appSettings.numberOfDc;
    }
  },

  created() {
    if (this.$store.getters[STORE.user.isAuthenticated]) {
      this.$router.replace(this.$t("r_page_productSearch_url"));
    } else {
      const user = sessionHelper.getUser();

      if (!objectHelper.isNull(user)) {
        this.$store.commit(STORE.user.set, { authResult: user });

        if (!user.user.isCSR) {
          this.$store
            .dispatch(STORE.orderBasket.get, user.customer.customerID)
            .then(() => {
              if (!this.$store.getters[STORE.orderBasket.isOrderBasketEmpty]) {
                const orderBasket = this.$store.state.orderBasket.orderBasket;

                const branchNumber = orderBasket[0].branchNumber;

                this.$store.commit(STORE.part.setBranchNumber, {
                  branchNumber
                });
              }
            });
        }

        const url = stringHelper.isNullOrWhiteSpace(this.$route.query.url)
          ? this.$t("r_page_productSearch_url")
          : this.$route.query.url;

        this.$router.replace({ path: url, query: this.$route.query.query });
      }
    }
  },

  mounted() {
    this.$refs.username.focus();
  },

  methods: {
    async login() {
      // Google Analytics, using VueAnalytics
      this.$ga.event("login", "btnLoginClick", "username", this.username);

      this.errorCode = 0;

      if (stringHelper.isNullOrWhiteSpace(this.username)) {
        this.errorCode = 5; // => Username cannot be empty.
      } else if (stringHelper.isNullOrWhiteSpace(this.password)) {
        this.errorCode = 6; // => Password cannot be empty.
      } else {
        const command = {
          username: this.username,
          password: this.password
        };

        const authResult = await userService.login(command);

        if (errorHelper.isError(authResult)) {
          this.errorCode = 999;
        } else {
          this.errorCode = authResult.errorCode;

          if (this.errorCode === 0) {
            this.$store.commit(STORE.user.set, { authResult });

            if (!authResult.user.isCSR) {
              await this.$store.dispatch(
                STORE.orderBasket.get,
                authResult.customer.customerID
              );

              if (!this.$store.getters[STORE.orderBasket.isOrderBasketEmpty]) {
                const orderBasket = this.$store.state.orderBasket.orderBasket;

                const branchNumber = orderBasket[0].branchNumber;

                this.$store.commit(STORE.part.setBranchNumber, {
                  branchNumber
                });
              }
            }

            const changePwd = await this.$_confirmChngPwd();

            if (!changePwd) {
              this.$router
                .push(
                  this.$route.query.url
                    ? this.$route.query.url
                    : this.$t("r_page_productSearch_url")
                )
                .catch(() => {});
            }
          }
        }
      }
    }
  }
};
</script>

<template>
  <div class="login">
    <layout>
      <b-row>
        <b-col md="auto" lg="5">
          <div
            v-if="isOldIe"
            class="panel-oldIe"
            v-html="$t('r_login_oldBrowser')"
          ></div>
          <div v-else class="panel-login">
            <h2 class="no-margin">{{ $t("r_login_customerLogin") }}</h2>
            <div
              class="login-info"
              v-html="$t('r_login_customerr_login_text')"
            />
            <b-form @submit.stop.prevent="login">
              <div class="login-form">
                <label for="username">{{ $t("r_login_username") }}</label>
                <b-form-input
                  id="username"
                  ref="username"
                  v-model="username"
                  type="text"
                  maxlength="7"
                  autofocus
                />
                <label for="password">{{ $t("r_login_password") }}</label>
                <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  maxlength="25"
                />
              </div>
              <div
                v-if="errorCode !== 0"
                class="text-red bold no-margin"
                v-html="errorMsg"
              />
              <button id="btnLogin" type="submit" class="btn-red">
                {{ $t("r_login_btnLogin") }}
              </button>
            </b-form>
          </div>
        </b-col>
        <b-col md="auto" lg="7" class="mt-md-5 mt-lg-0">
          <h2>{{ $t("r_login_welcome_title") }}</h2>
          <p
            v-html="
              $t('r_login_welcome_text_01', { numberOfEmployees, numberOfDc })
            "
          ></p>
          <p v-html="$t('r_login_welcome_text_02')"></p>
          <p v-html="$t('r_login_welcome_text_03')"></p>
          <p v-html="$t('r_login_welcome_text_04')"></p>
          <p class="text-red" v-html="$t('r_login_welcome_text_05')"></p>
        </b-col>
      </b-row>
      <hr />
      <div class="panel-new-product">
        <h2 v-html="$t('r_login_product_title')"></h2>
        <p v-html="$t('r_login_product_text_01')"></p>
        <p v-html="$t('r_login_product_text_02')"></p>
        <h3 class="title-offer" v-html="$t('r_login_product_text_03')"></h3>
      </div>

      <component-banner-catalog />
    </layout>
  </div>
</template>

<style scoped>
.panel-login {
  text-align: center;
  background: #f5f5f5;
  padding: 40px;
}

.panel-login .login-form {
  text-align: left;
}

.panel-login .login-form label {
  font-size: 14px;
}

.panel-login .login-form input[type="text"],
.panel-login .login-form input[type="password"] {
  margin-bottom: 19px;
}

.panel-login .login-info {
  margin-top: 24px;
  font-weight: 600;
  margin-bottom: 29px;
}

#btnLogin {
  margin-top: 19px;
  width: 80%;
}

.login hr {
  background: #afb1b4;
  height: 1px;
  padding: 0;
  margin: 30px 0 60px;
  box-sizing: border-box;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-new-product {
  padding: 0 7%;
  text-align: center;
  line-height: 1.8;
}

.panel-new-product .title-offer {
  margin: auto 150px;
}

.panel-oldIe {
  text-align: center;
  border: 5px solid #900;
  padding: 14px 16px 16px;
}
</style>
