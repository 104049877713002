<script>
export default {
  name: "ComponentBannerCatalog",

  computed: {
    catalogUrl() {
      return "/Content/PDF/catalog.pdf";
    }
  },

  methods: {
    // Google Analytics, using VueAnalytics
    getCatalog(method) {
      this.$ga.event("bannerCatalog", "getCatalog", "method", method);
    }
  }
};
</script>

<template>
  <b-row class="catalog">
    <b-col cols="4">
      <div class="imgCatalog">
        <img src="@/assets/images/catalog.png" :alt="$t('r_catalog')" />
      </div>
    </b-col>
    <b-col cols="8">
      <div class="catalog-detail">
        <p class="title">{{ $t("r_bannerCatalog_title") }}</p>
        <p class="subTitle">{{ $t("r_bannerCatalog_text_01") }}</p>
        <div class="action">
          <a
            id="btnView"
            :href="catalogUrl"
            class="btn-red"
            @click="getCatalog('bannerCatalog_view')"
          >
            {{ $t("r_bannerCatalog_view") }}
          </a>
          <br />
          <a
            :href="catalogUrl"
            class="downloadLink"
            download
            @click="getCatalog('bannerCatalog_download')"
          >
            {{ $t("r_bannerCatalog_download") }}
            <span class="downloadIcon"></span>
          </a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<style scoped>
.catalog {
  background: url("~@/assets/images/bkg-cta.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-family: "Rajdhani", arial, sans-serif;
  margin-top: 80px;
  margin-left: 0px;
  margin-right: 0px;
  height: 227px;
  text-transform: uppercase;
  line-height: 1.2;
}

.catalog p {
  margin: 0;
}

.imgCatalog {
  width: 230px;
  position: relative;
  flex: 0 1 auto;
}

.imgCatalog img {
  margin-top: -60px;
  margin-left: 40px;
}

.catalog .catalog-detail {
  padding: 20px 0;
}

.catalog .catalog-detail {
  text-align: center;
  flex: 1 0 auto;
  padding-left: 0px;
}

.catalog .action {
  display: inline-block;
  margin-top: 10px;
  text-align: center;
}

#btnView {
  width: 245px;
  font-size: 16px;
  margin-bottom: 10px;
}

.downloadLink {
  font-weight: 700;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  padding: 10px;
  display: inline-block;
  width: 245px;
  text-decoration: none;
}

.downloadLink span {
  margin-left: 10px;
}

.catalog .action a {
  color: #fff;
}

.catalog .title {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
}

.catalog .subTitle {
  font-size: 24px;
  line-height: 28px;
}
</style>
