import { httpHelper } from "@/helpers";

const controller = "/customer/";

export const customerService = {
  async get(customerId) {
    try {
      const response = await httpHelper.get(controller, {
        params: { customerId }
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Customer.Get");

      return error;
    }
  },

  async getShipTo(customerId) {
    try {
      const response = await httpHelper.get(controller + "shipto", {
        params: { customerId }
      });

      return response.data;
    } catch (error) {
      httpHelper.notifyApiError(error, "API Call Customer.GetShipTo");

      return error;
    }
  }
};
